import enUS from 'vant/lib/locale/lang/en-US'
import _new from './modules/en/new'

const en = {
  _new,

  // 动态表单相关
  dynamicForm: {
    uploadFail100001: 'Technical issue detected, please try again',
    uploadFail100002: 'Video file size limit exceeded, please try again',
    uploadFail100003: 'Technical issue detected, please try again',
    uploadFail100004: 'Technical issue detected, please try again',
    uploadFail100005: 'The file format is not supported',
    uploadFail100006: 'Video duration cannot exceed {maxDuration} seconds',

    uploadInfo100000: 'This type of file does not support preview'

  },

  vn: 'VN',
  en: 'EN',
  ok: 'Ok',
  cancel: 'cancel',
  clearAll: 'Clear All',
  compare: 'Compare',
  addtoCompare: 'Add To Compare',
  remove: 'Remove',
  compulsory: 'Compulsory',
  netPayment: 'Net Payment',
  superNetPayment: 'Super Net Payment',
  superAllBonusPoint: 'All Bonus and Point amounts earned from this order shall be fully used to deduct the Payable Amount',
  allBonusPoint: 'All Bonus Amounts earned from this order shall be fully used to deduct the Payable Amount',
  confirmToUsed: 'Confirm to use Net Payment?',
  confirmToUsedSuper: 'Confirm to use Super Net Payment?',
  byChoosing: 'By choosing Net Payment, you agree and understand that the bonus receivable from this order shall be utilized and directly deducted from the Payable Amount.',
  byChoosingSuper: 'By choosing Super Net Payment, you agree and understand that the bonus and points receivable from this order shall be utilized and directly deducted from the Payable Amount.',
  netConfirm: 'Confirm',
  netCancel: 'Cancel',
  dutyDesc: 'Warranty conditions are subject to the policy information generated by the insurance company.',
  register: {
    invalidReferralCode: 'Invalid Referral Code',
    contactDesc: 'Please contact us for support',
    mobileEmpty: 'Please enter your mobile number.',
    mobileInvalid: 'Sorry, this mobile number is invalid.',
    codeTipS: 'Please enter the 4-digit code we sent to',
    codeTipE: 'by SMS.',
    codeFail: 'Sorry, your verification code hasn’t been sent successfully. Please try again.',
    thCodeFail: 'Sorry, your OTP code hasn’t been sent successfully. Please try again.',
    codeEmpty: 'The verification code should be 4 digits.',
    thCodeEmpty: 'The OTP code should be 4 digits.',
    codeInvalid: 'Mobile number or verification code is incorrect.',
    thCodeInvalid: 'Mobile Number or OTP Code is Incorrect',
    nameEmpty: 'Please enter your nickname.',
    nameInvalid: 'Sorry, the nickname should begin with letters, and can be combined with numbers, underscores, and spaces.',
    passwordEmpty: 'Please set a password for your account.',
    passwordInvalidStyle: 'Sorry, only letters, numbers, and special characters(except spaces) are allowed.',
    passwordInvalidLength: 'Please set a password with at least 6 characters.',
    passwordEasy: 'This password is too simple and easy to guess.',
    emailInvalid: 'Please input the correct email format.',
    send: 'Send',
    reSend: 'Resend',

    voiceTipS: 'We will call the phone number ',
    voiceTipE: ', please kindly answer it.',

    title: 'Sign Up',
    labelMobile: 'Mobile Number',
    labelCode: 'Verification Code',
    thOTPCode: 'OTP Code',
    thGetOTP: 'Get OTP',
    labelName: 'Nickname',
    thLabelName: 'Full name',
    emailAddress: 'Email Address',
    labelPass: 'Password',
    laConfirmPass: 'Confirm Password',
    voice: 'Get Voice Verification Code',

    // thaiPHPhone: 'e.g. 0999999999',
    thaiPHPhone: 'Please enter a 10 digit phone number',
    placeholderMobile: 'e.g. 77998xxxx',
    placeholderCode: 'e.g. 6128',
    placeholderName: 'e.g. Nguyễn Văn An',
    placeholderPass: 'A combination of 6 to 20 letters and numbers',
    thPlacePass: 'Minimum of 6 characters',
    sign: 'Sign Up',
    tip: 'By signing up, you agree with our',
    and: 'and',
    tc: 'Terms & Conditions',
    ps: 'Privacy Policy',
    pp: 'Privacy Policy',
    copy: 'Fuse Tech Company Limited',

    confirmPwd: 'The confirmation password is inconsistent with the password input',
    successTit: 'Congratulations!',
    successTxt: `You have successfully registered Fuse Pro and now
    become an official Fuse Pro Partner! Fuse Pro app is
    available in iOS and Android version. Please
    download and start making deals like a pro! Thank
    You!!`,
    sucDesc: 'Please open our mobile app to verify your account.',
    OpenFusePro: 'Open FUSE Pro',
    returnToHomepage: 'Return to homepage',
    openDesc: 'Don’t have FUSE Pro yet? You will be redirected to your App Store by tapping on the button above.',
    successIOS: 'Download on the',
    successAndroid: 'Get it',

    registered: 'has already registered.',
    needHelp: 'Need Help?',
    telephone: 'Telephone',
    email: 'Email',
    earnBonus: 'Sign Up for free to enjoy the exclusive benefits!',
    registerTit: 'Become a Fuse Pro Partner',
    registerTPBTit: 'Become a Partner',
    referrerNickname: 'Referrer Nickname',
    referrerNicknameTips: `Nickname of the person that referred you to us`,
    signInNow: 'Sign In Now',
    goToMarketplace: 'Go To Marketplace',
    aeciTab: 'You have successfully created AECI x TAB account',
    havingTrouble: 'Having trouble signing up ? ',
    contactUs: 'Contact Us',
    phoneCall: 'Phone Call',
    line: 'LINE',
    copyright: 'Fuse Tech Solution (Thailand) Co., Ltd. All Rights Reserved.',
    pleaseSel: 'Please Select',
    province: 'Province and District',
    provinceEmpty: 'Please select Province and District',
    outOfDate: 'This page is out of date',
    forAssistance: 'you may contact for assistance',
    tel: 'Tel.'
  },
  firstLogin: {
    title: 'Sign Up Mobile Number',
    formDesc: 'Please enter your mobile number and the verification code.',
    Oops: 'Oops... something went wrong',
    codeTo: 'Please check your Verification Code we just sent',
    congratulations: 'Congratulations!',
    congraDesc: 'You’ve successfully registered your mobile number. We’ve sent you an email with the initial password.',
    alreadyRegistered: 'You’ve already registered your mobile number.',
    pageOutOfDate: 'This page is out of date.'
  },
  improvementText: {
    chooseCmi: 'If CMI is included, installment by Finance Service Provider is not applicable',
    SystemUnderMaintenanceTit: 'System is under maintenance',
    SystemUnderMaintenanceDesc: `We're preparing to enhance your in-app experience. During this time, certain functionalities may be temporarily unavailable. If you need assistance, please do not hesitate to contact us.`
  },
  payAbout: {
    signDia: {
      tit: 'Please Sign Agreement to Continue',
      content: 'The Net Payment feature is exclusively applicable to partners who have signed a Partnership Agreement with Fuse.',
      superContent: 'The Super Net Payment feature is exclusively applicable to partners who have signed a Partnership Agreement with Fuse.',
      later: 'Later',
      signNow: 'Sign Now'
    },
    payNow: 'Pay Now',
    payNowDesc: 'Underwriting will start after this order is paid',
    payLater: 'Pay Later',
    payLaterDesc: 'Please pay to this order after underwriting processing is completed',
    apply: 'Apply',
    discountToCustomer: 'Discount to Customer',
    discountInfo: 'Discount Information',
    discount: 'Discount',
    discountTit: 'How much would you like to discount?',
    discountDesc: 'Maximum discount from commission'
  },
  requireMore: {
    title: 'Please provide below additional details',
    defaultDesc: 'Our staff will be reaching out to you to clarify the additional information required.',
    quotationSlip: 'Quotation Slip'
  },
  oic: {
    signature: 'Signature',
    consentTit: 'I agree to buy insurance',
    cD1: 'According to the conditions',
    cD2: '(a) I certify that I am in good health. No part is disabled or handicapped, and no chronic congenital disease, serious disease, not insane. No symptoms or diseases associated with mental conditions alcoholism and not a drug addict and certify that The statements listed above are true and to be considered part of the insurance contract between me and the company, Bangkok Insurance Public Company Limited',
    cD3: '(b) I acknowledge the terms and conditions of underwriting under the coverage plan that I have chosen and agree to apply for insurance under the terms and such insurance conditions',
    cD4: '(c) I agree to let the company collect, use and disclose facts about my health and information to the Office of Insurance Commission for the benefit of regulating the insurance business',
    cD5: '(d) I hereby certify that the information given is true in all respects and agreed to the sum insured and "I hereby certify and confirm that I have no history of violating the Anti-Money Laundering or Anti-Terrorism Financing Act and proliferation of weapons of mass destruction or other relevant laws, such as the law on narcotics." And I hereby certify that the above detailed information is true in all respects.',
    cD6: 'Warning of the Office of Insurance Commission (OIC) Answer all of the above questions truthfully. If the insured conceals the true message or making false statements will result in void of this contract. which the company has the right to void the insurance contract according to the Civil and Commercial Code, Section 865',
    cD7: 'I agree to allow the company to use my phone number. mobile phone number and/or email provided above In notifying the results of my approval or other new company product offering And I will notify the company immediately if there is any change.',
    cD8: 'I consent to the collection, use or disclosure of health information.',
    cD9: 'I am happy to receive news, information, communication, privileges special promotion and various activities of the company organized for customers of Bangkok Insurance Public Company Limited and consent to the company collecting, using or disclosing my personal data for such actions.'
  },
  aeci: {
    b1Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b2Tit: 'Terms & Conditions',
    b2Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b3Tit: 'Disclaimer',
    b3Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Tit: 'FAQ',
    b4Q1: 'Lorem ipsum dolor sit amet',
    b4Q1Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Q2: 'Lorem ipsum dolor sit amet',
    b4Q2Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    b4Q3: 'Lorem ipsum dolor sit amet',
    b4Q3Desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    footerDesc: 'I have read and agree to allow FUSE Tech Thai to generate the account'
  },
  payment: {
    success: 'Transaction paid successfully!',
    successDesc: 'Congratulations! Your transaction has been completed.',
    cancel: 'Your transaction was canceled!',
    cancelDesc: 'So sorry! Your transaction has been canceled. Please directly contact counsellor for support and more detail information.',
    validTimeTips: ' the payment within',
    payHint: 'How would you like to pay?',
    bankStep1: 'Open Mobile Banking or Internet Banking that you are using.',
    bankStep2: 'Use quick transfer (24/7) to transfer money to Fuse’s bank account according to the information below. Note: Account number will be changed each time. Please, ensure to use the new account number.',
    bankStep3: 'Your order will be processed within 24 hours. When you need further information, please don\'t hesitate to contact us',
    orderNo: 'Order No.',
    productName: 'Product Name',
    benefitPack: 'Benefit Package',
    policyHolder: 'Policy Holder',
    totalPremium: 'Total Premium',
    totalPayment: 'Total Payment',
    policyInformation: 'Policy Information',
    changePaymentHint: 'Last payment information of this order will be cancelled and cannot use any more.',
    changePaymentTitle: 'Are you sure to change Payment Method?',
    bankTransferTips: 'Please follow the steps below',
    payableAmount: 'Payable Amount',
    bankName: 'Bank Name',
    bankAccountNumber: 'Bank Account Number',
    bankAccountName: 'Bank Account Name',
    transferBefore: 'Transfer Before',
    step: 'Step',
    premiumBenificiary: 'Premium Benificiary'
  },
  successed: 'Succeed',
  queryCompError: 'Something wrong with the price, please select it again',
  viewMoreOptions: 'View More Options',

  //
  'pleaseSelectBenefitPackage': 'Please select "Benefit Package"',
  'productExpired': 'This product is out of sale time ',
  'enterAmount': 'Please enter the amount',
  'enterPercentage': 'Please enter a percentage',
  'checkPlanWarning': 'Please select a package',
  'benfitDetails': 'Benefit Detail',
  'productDetails': 'Product Details',
  'productDescription': 'Product Description',
  'productIntroduction': 'Product Introduction',
  'caseStudy': 'Case Study',
  'productTerms': 'Product Terms',
  'productSummary': 'Product Summary',
  'claimsDetails': 'Claim Detail',
  'faq': 'FAQ',
  'tipsForPurchase': 'Tips for Purchase',
  'consentStatement': 'Consent Statement',
  'contentsOfDsclaimer': 'Contents of Dsclaimer',
  'details': 'Details',
  premiumDetails: 'Details',
  orderInfomation: 'Order Information',
  mainInformation: 'Main Information',
  'insuranceInformation': 'Insurance Information',
  'benefitPackage': 'Benefit Package',
  'policyAmount': 'Policy Amount',
  'benefitPlan': 'Benefit Plan',
  'benefitPlans': 'Benefit Plans',
  'adminFee': 'Admin Fee',
  'serviceFee': 'Service Fee',
  'subTotalFee': 'SubTotal Fee',
  'discount': 'Discount',
  'total': 'Total',
  bottTotal: 'Total',
  'moreDetails': 'More Details',
  'buyNow': 'Buy Now',
  'confirm': 'Confirm',
  'benfitPackageDetails': 'Benefit Package Details',
  'coverage': 'Coverage',
  'benefit': 'Benefit',
  'amount': 'Amount',
  'insuranceDetails': 'Insurance Details',
  'productName': 'Product Name',
  'policyPeriod': 'Policy Period',
  'startDate': 'Effective Date',
  'endDate': 'Expiry Date',
  'policyDiscount': 'Policy Discount',
  'close': 'Close',
  'subTotal': 'SubTotal',
  'enterDiscount': 'enter discount',
  'pleaseEnterDiscountRate': 'Please enter the amount of this order policy discount rate',
  'insuredAmount': 'Insured Amount',
  'totalInsurePerson': 'Total Insured Person',
  'coverageDiscount': 'Coverage Discount',
  'pleaseEnterCoverageDiscountRate': 'Please enter the amount of this coverage discount rate',
  'seeDetails': 'See Details',
  'proofToast': 'Because the valid payment period of the order has expired, you can no longer upload payment proof.',

  order: {
    statusMap: {
      1: 'Processing', // 正在处理中
      2: 'Completed', // 完成，当订单中所有投保单状态变成终态或生成保单，则订单状态变为Completed
      3: 'Declined'
    },
    orderExpires: 'Order expires in',
    orderDeclined: 'Order Declined'
  },

  'policy': {
    'details': {
      'orderId': 'Order ID',
      'orderNo': 'Order Number',
      'policyNumber': 'Policy Number',
      'copy': 'Copy',
      'copyToast': 'Copied',
      'status': 'Status',
      'statusMap': {
        1: 'Inactive',
        2: 'Effective',
        3: 'Expired',
        4: 'Surrender'
      },
      'slipStatusMap': {
        1: 'To Be Confirmed',
        2: 'Underwriting',
        3: 'Approved',
        4: 'Declined'
      },
      'paymentStatus': 'Payment Status',
      'paymentStatusMap': {
        1: 'Unpaid',
        2: 'Paid',
        3: 'Cancelled'
      },
      'insuranceCompanyFive': 'Insurance Company Five',
      'seeDetails': 'See Details',
      viewDetails: 'View Details',
      'coveragePeriod': 'Coverage Period',
      'orderTime': 'Order Time',
      'paymentTime': 'Payment Time',
      'benefitDetails': 'Benefit Detail',
      'claimdetails': 'Claim Detail',
      'claimdetailsBtn': 'Claim Detail',
      'payNow': 'Pay Now'
    },
    'seeDetails': {
      'toastTotalPremium': 'The Total Premium will be used as the basis to calculate the bonus of Partners. Bonus of partner is X% of Total Premium excluding 10% VAT and after deduction of service fees (if any).',
      'totalPremium': 'Total Premium',
      'insuranceCompanyFive': 'Insurance Company Five',
      'effectiveDate': 'Start Date',
      'expirationDate': 'End Date',
      'premiumDetail': 'Policy Amount Details',
      'Insurance': 'Insurance',
      'maincoverage': 'Main Coverage',
      'additionalCoverage': 'Additional Coverage',
      'serviceFee': 'Service Fee',
      'bonusAmount': 'Earned Bonus',
      'couponPoints': 'Coupon Points',
      'incomeTaxofBonusDeduction': 'Personal Income Tax',
      'receivedBonus': 'Received Bonus',
      'adminFee': 'Admin Fee',
      'total': 'Total',
      instantService: 'Instant service',
      quickClaim: 'Quick claim',
      fullCoverage: 'Full coverage',
      'couponApplied': 'Coupon Applied',
      'bonus': 'Bonus',
      'paymentAmount': 'Payment Amount',
      'fusePoints': 'Fuse Points',
      'extraPoints': 'Extra Points',
      'rewardPoints': 'Reward Points',
      'supportAmountFromExtraPoints': 'Support Amount From Extra Points',
      'receivedFusePoints': 'Received Fuse Points'
    },
    'benkTransferProof': 'Bank Transfer Proof'
  },
  paymentAmount: 'Payment Amount',
  coupon: 'Coupon',
  couponAdd: 'Add Coupon',
  couponAvailable: 'Coupon Available',
  policyDetails: 'Policy Details',
  confirmProceed: 'Confirm & Proceed',
  packageDetail: 'Overview',
  description: 'Product Description',
  benefitPackages: 'Benefit Packages',
  whichPackage: 'Which package would you like to proceed with ?',
  coSel: 'coverage selected',
  benefitPackDetails: 'Benefit Package Details',
  plan: 'Plan',
  chooseExtendedCoverage: 'Choose additional benefits (if any)',
  coverInAm: 'Coverage Insured Amount',
  and: 'and',
  otherBenefit: 'other benefit(s)',
  premium: 'Premium',
  personalIncomeTax: 'Personal Income Tax',
  fusePoints: 'Fuse Points',
  extraPoints: 'Extra Points',
  rewardPoints: 'Reward Points',
  supportAmountFromExtraPoints: 'Support Amount From Extra Points',
  receivedFusePoints: 'Received Fuse Points',
  earnedBonus: 'Earned Bonus',
  receivedBonus: 'Received Bonus',
  mainCover: 'Main Coverage',
  addiCover: 'Additional Coverage',
  viewDetail: 'View Detail',
  TotalInPer: 'Total Insured Person',
  DeducAm: 'Deductible Amount',
  premSum: 'Premium Summary',
  premDetails: 'Premium Details',
  more: 'More',
  clickHere: 'Click here to see selected package',
  deductiAmount: 'Deductible Amount',
  aNTotal: 'Total',
  premiumExcludeVat: 'Premium (Exclude VAT)',
  premiumIncludeVat: 'Premium (Include VAT)',
  proof: 'Proof',
  marketablePro: 'Product Name',
  benefitPack: 'Benefit Package',
  orderTime: 'Order Time',
  policyNumber: 'Policy Number',
  insuranceSlipNumber: 'Insurance Slip Number',

  next: 'Next',
  payNow: 'Pay Now',
  payLater: 'Pay Later',
  select: 'Select',
  month: 'Month(s)',

  // th
  byAEC: 'AEC Insurance Broker Company Limited',
  ePolicy: 'E-Policy',
  chooseSumInsured: 'Choose Sum Insured You Need',
  theDeductibleIs: 'Deductible Amount is paid by you before an insurance provider pays any expenses.',
  selectPlan: 'Select Plan',
  pSYPP: 'Please select your preferred plan',
  numProductsFound: 'Products Found',
  noPF: 'No product found, please reselect the filter criteria',
  loadingNoPF: 'We are curating the insurance products for you',
  thRfq: {
    declinebtn: 'Decline',
    getQuotation: 'Get Quotation',
    negotiationRequest: 'Negotiation Request',
    quoteDecline: 'Quote Decline',
    decline: 'Decline Reason',
    dRIsRequired: `'Decline Reason' is required`,
    VehicleNotFound: 'Vehicle not found?',
    noPlanFound: 'No Plan Found',
    requestQuote1: 'Request Quote ',
    requestQuote2: 'from insurance companies',
    planNotFound: `Plan not found?`,
    letUsKnow: `Let us know and we'll quote it`,
    foundBetter: 'Found better deals?',
    letUsKnowMatch: `Let us know and we'll match it`,
    succRequest: 'Successfully Request!',
    weAreGlad: 'We are glad to serve your request. The quotation will update to you soon in Quote List',
    backToHome: 'Back to Home',
    viewQuote: 'View Quote',
    yourSubmission: 'Your submission is being reviewed',
    yourSubMissionDesc: 'Your submission is being proceeded within 1-3 workdays. Please contact us for further assistance',
    call: 'Call',
    close: 'Close',
    generalInfo: 'General Information',
    rfqId: 'RFQ ID',
    createdTime: 'Created Time',
    status: 'Status',
    email: 'Email',
    quotes: 'Quote(s)',
    requireMoreData: 'Require More Data',
    requireMoDesc: 'Would you please provide more data to insurance to get the appropriate quotes?',
    qsId: 'QS ID',
    quotedPrice: 'Quoted Price',
    expiryDate: 'Expiry Date',
    quoteDetail: 'Quote Detail',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    processing: 'Processing',
    quoted: 'Quoted',
    declined: 'Declined',
    expired: 'Expired',
    accepted: 'Accepted',
    confirmed: 'Confirmed',
    toBeConfirmed: 'To Be Confirmed',
    needMoreData: 'Need More Data',
    kindlyRemind: 'Kindly remind you this quotation will expire in',
    bonus: 'Bonus',
    specialBonusFrom: 'Special Bonus from Points',
    commission: 'Commission',
    personalIncomeTax: 'Personal Income Tax',
    receivedCommission: 'Received Commission',
    thisCommissionFigure: 'This commission figure may not be exatly equal to the actual receiable commission amount',
    premium: 'Premium',
    totalPremium: 'Total Premium'
  },
  sumInsuredAmount: 'Sum Insured Amount',
  deductibleAmount: 'Deductible Amount',
  thirdPartyAmount: 'Third Party Protection',
  filter: 'Filter',
  insuranceType: 'Insurance Type',
  repairType: 'Repair Type',
  insuranceCompany: 'Insurance Company',
  all: 'All',
  allInsuranceCompany: 'All Insurance Company',
  allTypes: 'All Insurance Types',
  class1: 'Class 1',
  class2: 'Class 2',
  class3: 'Class 3',
  class2Plus: 'Class 2+',
  class3Plus: 'Class 3+',
  priceRange: 'Premium Range',
  allPrice: 'All Price',
  THB: 'THB',
  reset: 'Reset',
  applyFilters: 'Apply Filters',
  repairCenter: 'Repair Center',
  garage: 'General Garage',
  dealer: 'Dealer Garage',
  earn: 'Earn',
  commission: 'Bonus',
  specialBonus: ' Special Bonus',
  points: 'Points',
  totalSpecialBonus: 'Total Special Bonus',
  planDetail: 'Plan Detail',
  totalPremium: 'Total Premium',
  newSumInsured: 'Sum Insured Amount for Car Damage',
  dedAmount: 'Deductible Amount on Car Damage',
  includeCompulsoryMotor: 'Include Compulsory Insurance',
  bankTransfer: 'Bank Transfer',
  uploadProof: 'Upload Proof',
  page: 'Page',
  requestNumber: 'Request Number',
  currency: 'Currency',
  coveragePeriodUnit: {
    unit1: 'Day',
    unit2: 'Month',
    unit3: 'Year'
  },
  duty: {
    unit2: 'Day',
    unit3: 'Year',
    unit4: 'Disability',
    unit5: 'Confinement',
    unit6: 'Pregnancy',
    unit7: 'Time',
    unit8: 'Person'
  },
  loadingProductDesc: 'We are curating the insurance products for you',
  free: 'Free',
  yes: 'Yes',
  no: 'No',
  yes2: 'Yes',
  no2: 'No',
  orderDetail: 'Order Detail',
  orderDate: 'Order Date',
  paymentProof: 'Payment Proof Pending Review',
  paymentPic: 'Order Payment Proof',
  orderGenerated: 'The order is being generated, please wait for a moment',
  status: 'Status',
  pay: 'Pay',
  payableAmount: 'Payable Amount',
  policyNo: 'Policy No.',
  insuredPeriod: 'Insured Period',
  theInsured: 'The Insured',
  quoteDate: 'Quote Date',
  save: 'Save',
  share: 'Share',
  quotationFrom: 'Quotation from Insurance Companies',
  thisQuotationTable: 'This quotation is for reference only, subject to actual coverage at purchase. © Fuse Tech Solution (Thailand) Co., Ltd.',
  thisQ1: 'Remarks:',
  thisQ2: '1. This document is for quotation purpose only. The company reserves the right to cancel, update, or modify the quotation.',
  thisQ3: '2. FusePro serves as an insurance marketing system provided by AEC Insurance Broker Company Limited.',
  thisQ4: '3. Warranty terms and conditions are as specified by the insurance company.',
  thisQ5: '4. Customers are obliged to understand the details of coverage and Term and Conditions before deciding to purchase the insurance product.',
  thisQ6: '5. AEC Insurance Broker Company Limited is an insurance broker and registered under the license number of ว00019/2563.',
  termsAndConditions: 'Quotation slip created by Fuse Online Co., Ltd. (“Company”) electronically. The Partner shall deliver this Quotation Slip to the prospective policy owner. If there is any material information or question from the prospective policy owner regarding this Quotation Slip, the Partner shall inform such question immediately to Company.',

  pricing: {
    newPrecautions: 'The Income figure is for reference only, the actual value may be adjusted if there is a change before payment is completed.',
    precautions: 'This bonus figure may not be exactly equal to the actual receviable commission amount',
    'bonus': 'Bonus',
    'commission': 'Bonus',
    'premiumDetail': 'Premium Detail',
    'Premium': 'Premium',
    'adminFee': 'Admin Fee',
    'serviceFee': 'Service Fee',
    'policyAmount': 'Policy Amount',
    'close': 'Close',
    'pit': 'PIT',
    'pitFullForm': 'Personal Income Tax',
    'receivedCommission': 'Received Bonus',
    'cancel': 'Cancel',
    'apply': 'Apply',
    'fusePoints': 'Fuse Points',
    'extraPoints': 'Extra Points',
    'rewardPoints': 'Reward Points',
    'supportAmountFromExtraPoints': 'Support Amount From Extra Points',
    'receivedFusePoints': 'Received Fuse Points',
    'paymentAmountDetail': 'Payment Amount Detail',
    'paymentAmount': 'Payment Amount'
  },

  pricingList: {
    describe: 'Sorry, no products found',
    guide: 'Please try a different search term or contact our customer service at 028 7300 8803 for better assistance'
  },

  comparisonList: {
    describe: 'No comparison at the moment'
  },

  decline: 'Decline',
  proceed: 'Proceed',
  accepted: 'Accepted',
  expiryDate: 'Expiry Date',
  validUntil: 'Valid Until',
  quoteNo: 'Quote No.',
  quote: 'Quote',
  createdTime: 'Created Time',
  rfQNo: 'RFQ No.',
  packageName: 'Package Name',
  generalInformation: 'General Information',
  rfqStatus: 'Status',

  noMoreData: 'No data',
  quoteDecline: {
    declineTheQuote: 'Decline Reason',
    tips: 'Please select the reason for declining the quotation: ',
    reason: {
      1: 'Changed my mind',
      2: 'Incorrect information entered',
      3: 'Price is not attractive'
    },
    rulesMessage: 'Please select the reason',
    submitting: 'Submitting...'
  },

  submit: 'Submit',
  declineReason: {
    1: 'Changed mind',
    2: 'Entered incorrect information',
    3: 'Price is not good/attractive',
    4: 'Expired'
  },
  quoteDetails: {
    validTimeTips: 'Kindly remind you this quote will be expired in: ',
    validTimeExpiredTips: 'Quotation Expired',
    validTimeDeclinedTips: 'Quotation Declined',
    validTimeAcceptedTips: 'Quotation Accepted',
    insuranceCompany: 'Insurance Company',
    productName: 'Product Name',
    quoteNo: 'Quote No',
    status: 'Status',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    declinedReason: 'Declined Reason',
    declined: 'Declined',
    feeDetail: 'Fee Detail'
  },
  processing: 'Processing',
  quoted: 'Quoted',
  declined: 'Declined',
  toBeConfirmed: 'To Be Confirmed',
  confirmed: 'Confirmed',
  expired: 'Expired',
  needMoreData: 'Need More Data',

  selectedCoverages: 'Selected Coverages',
  totalPolicyAmount: 'Total Policy Amount',

  overView: {
    productOver: 'Product Overview',
    claimDetails: 'Claim Detail',
    productDescription: 'PRODUCT DESCRIPTION',
    productPlans: 'PRODUCT PLANS',
    otherInformation: 'OTHER INFORMATION',
    claimProcedures: 'CLAIM PROCEDURES',
    claimDocuments: 'CLAIM DOCUMENTS'
  },

  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  orderNo: 'Order No.',
  paymentDue: 'Order Payment due at',
  informationCompletion: 'Information Completion',
  completeInfoTitle: 'Successfully completed inspection information!',
  completeInfoDesc: 'Congratulation! You have completed all inspection information. Please ask your partner make a payment to complete the order.',
  completeHealthTitle: 'Successfully completed health questionnaire!',
  completeHealthDesc: 'Congratulation! You have completed all inspection information. Please ask your partner make a payment to complete the order.',
  expireTitle: 'The link is Expired!',
  expireDesc: 'Please ask your counselor to re-send the link',
  expireDescID: 'Please ask your partner to re-send the link',
  policyInformation: 'Policy Information',
  submitInspectionInformation: 'Submit Inspection Information',
  verificationCode: 'Verification Code',
  askPartnerPay: 'Ask Partner Pay',
  complete: 'Complete',
  otpDesc: `Please enter the 4-digit code we sent to {mobileNo} by SMS`,
  send: 'Send OTP',
  otpError: 'OTP is not correct',
  viewInspectionInformation: 'View inspection Information',
  needOTPDesc: `This form required verified code to submit, make sure your mobile number as same as {mobileNo}`,
  submitInspection: `Submit Inspection`,
  invalidPlatform: 'Need to capture image on mobile or camera permission disabled',
  inputVerification: 'Please insert the verification code.',

  ...enUS,

  meta: {
    'Quotation Form': 'Quotation Form',
    'Pricing Options': 'Pricing Options',
    'Pricing Details': 'Pricing Details',
    'Pricing Application': 'Application Form',
    'International Travel': 'Product Information',
    'General Information': 'General Information',
    'Quote Details': 'Quotation Detail',
    'Policy Details': 'Policy Detail',
    'Order Details': 'Order Details',
    'Benefit Claim Detail': 'Benefit & Claim Detail',
    'Purchase': 'Purchase',
    'Partnership': 'Partnership',
    'Performance': 'Performance',
    'Direct Invited Downline': 'Direct Invited Downline',
    'Negotiation Request': 'Negotiation Request',
    'Comparison List': 'Comparison List',
    'Product Compare': 'Product Comparison',
    'Add Inspection Info': 'Add Inspection Info',
    'Vehicle Photos for Inspection': 'Vehicle Photos for Inspection',
    'Additional Information': 'Additional Information',
    'Request For Quote': 'Request For Quote',
    'Attachment Files': 'Attachment Files',
    'BillingDetail': 'Billing Detail',
    'WalletStatementDetail': 'Wallet Statement'
  },
  pricingDetails: 'Pricing Details',

  partnershipAgree: 'I hereby confirm that I already read and understand the contents of the Partnership Agreement, therefore agree to engage in such agreement with AEC Insurance Broker Co., Ltd',

  partnershipAgreeVN: 'I hereby confirm that I have read and understood the contents of the Partnership Agreement and the <a href="https://static.fuse.com.vn/xml/GeneralTerm.pdf">General Term</a> (click to view) and accordingly agree to enter into the agreement with Fuse Online Company Limited',

  placeholderSearch: 'Please enter the search keyword',

  carInspection: 'Vehicle Photos for Inspection',
  uploadVehiclePhotos: 'Upload Vehicle Photos',
  confirmSubmit: 'Are you sure you want to submit?',
  confirmDraft: 'Are you sure you want to save as draft?',
  inspectionSubmitTip: 'You can not change inspection information after submission.',

  province: 'Province/City',
  city: 'District',
  district: 'Ward',
  quotedPrice: 'Quoted Price',
  benefitAndClaim: 'Benefit & Claim Detail',
  slipNo: 'Insurance Slip No.',
  coveragePeriod: 'Coverage Period',
  express: 'Express',
  expressTip: 'The order will be proceeded quickly by the insurance company, even on weekends and holidays',
  loading: 'Loading...',
  paymentCountDownTip: 'Please complete the form and payment within',
  paymentCountDownTipID: 'Please complete the inspection info within',

  rebate: 'Support Amount',
  discountFromInsco: 'Discount from Insco',
  discountToCustomer: 'Support Amount from Bonus',
  information: 'Information',
  rebateTip: 'Support Amount should be deducted from your Income under this order, if you select this option, your Income under this order will be reduced.',
  rebateUpTo: 'Support Amount from Bonus up to',
  rebateCfgTit: 'Support Amount',
  rebatePlaceholder: 'How much would you like to support',
  maximumDiscount: 'Maximum Support Amount',
  requiredKYC: 'Required KYC',
  productFound: 'Products Found',
  SelectThePackage: 'Select the package',
  comparisonCode: 'Comparison Code',
  saveComparison: 'Save',
  quoteNumber: 'Quote Number',
  disclaimer: 'The Insured must read all the terms in this Quotation and submit a request to issue the Insurance Policy in case of agreeing with all the proposals in the Quotation. If we receive a request to issue an Insurance Policy, we automatically consider that the Insured has clearly understood and accepted all proposals stated in the Quotation.<br /><br /> This quotation is valid until [expiry_date]. If there is a difference between the terms in the Insurance Policy and the Quotation, the terms in the Insurance Policy shall prevail. <br/><br/> If customers have any questions regarding the Quotation, please contact us via email <a href="mailto:cs@fuse.com.vn" rel="nofollow">cs@fuse.com.vn</a> or call Hotline (028) 7300 8803.',
  quotation: 'QUOTATION',
  customerName: 'Customer Name',
  brandModel: 'Brand / Model',
  manufacturerYear: 'Manufacture Year',
  registerDate: 'Registration Date',
  vehicleType: 'Vehicle Type',
  usage: 'Usage',
  seatCapacity: 'Seat Capacity',
  carValue: 'Car Value',
  fromDate: 'From Date',
  toDate: 'To Date',
  quotationInformation: 'QUOTATION INFORMATION',
  disclaimerTitle: 'FULL ACCEPTANCE',
  updateComparison: 'Update Comparison',
  premiumInclVat: 'Premium incl VAT',
  allOption: 'All',
  priceRange1: 'Less than 2,000,000 VND',
  priceRange2: 'Over 4,000,000 - 6,000,000 VND',
  priceRange3: 'Over 6,000,000 VND',
  dutybenefit: {
    unit2: 'Day',
    unit3: 'Year',
    unit4: 'Disability',
    unit5: 'Confinement',
    unit6: 'Pregnancy',
    unit7: 'Time',
    unit8: 'Person'
  },
  maincoverage: 'Main Coverage',

  downline: {
    totalGwp: 'Total GWP',
    totalBonus: 'Total Bonus',
    totalPoints: 'Total Fuse Point',
    totalPolicy: 'Total Policy',
    ByProductCategory: 'By Product Category',
    premium: 'Premium',
    policy: 'Policy',
    ByInsuranceCompany: 'By Insurance Company',

    // date
    Updatedon: 'Updated on',
    Month: 'Month',
    Quarter: 'Quarter',
    Year: 'Year',

    // no order: ''
    noOrder: 'No orders in selected period',
    maxLv: 'Congratulation! You are at highest ranking now.',
    InviteFriends: 'Invite Friends',
    noPromission: 'You cannot see downlines of the partner have level equal or greater than you.',

    // myteam
    Myself: 'Myself',
    MyTeam: 'My Team',
    NextRank: 'Next rank',
    InvitationTree: 'Invitation Tree',
    TotalInvitedDownline: 'Total Invited Downline',
    NewInvitedDownline: 'New Invited Downline',
    InvitedTransactingDownline: 'Invited Transacting Downline',
    DirectInvitedDownline: 'Direct Invited Downline',

    Branch: 'Branch',
    TotalBranchDownline: 'Total Branch Downline',
    NewBranchDownline: 'New Branch Downline',
    BranchTransactingDownline: 'Branch Transacting Downline',
    BranchGWP: 'Branch GWP',
    BranchPolicy: 'Branch Policy',

    Contributing: 'Contributing',
    PassiveIncome: 'Passive Income',
    UplinePoint: 'Upline Point',
    PMPoint: 'PM Point',
    PDPoint: 'PD Point',
    PMBreakupPoint: 'PM Breakup Point',
    PDBreakupPoint: 'PD Breakup Point',
    TotalContributingDownline: 'Total Contributing Downline',
    NewContributingDownline: 'New Contributing Downline',
    ContributingGWP: 'Contributing GWP',
    ContributingPolicy: 'Contributing Policy',

    JoinedSince: 'Joined Since',
    DirectDownline: 'Direct Downline',

    PersonalContributingGWP: 'Personal Contributing GWP',
    PersonalPolicy: 'Personal Contributing Policy'
  },
  percentage: {
    text: `{name} of 100% achievement to next rank`
  },
  // nextRank
  'GWP(Branch)': 'Branch GWP',
  'GWP(Individual)': 'Personal GWP',
  'Branch RP': 'Branch Downline',
  'Branch TP': 'Branch Transacting Downline',

  // 通融相关
  negotiable: {
    negotiableTit1: 'Found better deals? ',
    negotiableTit2: "Let us know and we'll match it",

    negotiationQuoteStatus1: 'Negotiating',
    negotiationQuoteStatus2: 'Agreed to change premium',
    negotiationQuoteStatus3: 'Declined to change premium'
  },

  // 后台接口特定错误吗提示
  '200200002': "So sorry! You have negotiated 3 times! Let's review 3 previous offers and make the right decision!",

  // Inspection 相关
  later: 'Later',
  applicationConfirmInspectionTit: 'Do you want to share to customer?',
  applicationConfirmInspectionText: 'Inspection Information will be completed by customer. These information is important for issue policy.',
  inspectionRequired: 'Inspection Required',
  inspectionRejected: 'Inspection Rejected',
  inspectionInformation: 'Inspection Information',

  // Non Binder
  success: {
    title: 'Successfully Request!',
    desc: 'We are glad to serve your request. The quotation will update to you soon in Quote List.'
  },
  backToHome: 'Back To Home',
  viewQuote: 'View Quote',
  quotes: 'Quote(s)',
  quoteProcessing: {
    title: 'Processing',
    desc: 'Your quote is being processed and will be sent to you soon. Thank you for using Fuse Pro!'
  },

  attachmentFiles: 'Attachment Files',

  quoteNeedMoreData: {
    title: 'Require More Data!',
    desc: 'Please provide more data to get the competative quotation soon!'
  },

  cancelRequest: 'Cancel Request',

  nonBinderQuoteDecline: {
    title: 'Are you sure you want to decline the quote?',
    subtitle: 'Select the reason'
  },

  nonBinderRfqDecline: {
    title: 'Are you sure you want to cancel the RFQ?',
    subtitle: 'Select the reason'
  },

  // Binder Renew 相关
  renewable: {
    renewableTit1: 'Can’t find what you need? ',
    renewableTit2: 'Kindly suggest to assist us!'
  },
  nonBinderPolicy: {
    applicationFormTitle: 'Application Information',
    rfqFormTitle: 'RFQ Information',
    quotationFormTitle: 'Quotation Information'
  },

  // Coupon相关
  noCoupon: 'No Coupon Available for This Policy',
  changeCoupon: 'Change Coupon',
  addCoupon: 'Add Coupon',
  totalAmount: 'Total Amount',
  couponsInformation: 'Coupons Information',

  popupYes: 'Yes',
  popupNo: 'No',

  paymentInformation: 'Payment Information',
  insuredName: 'Insured Name',
  paymentFlow: 'Payment Flow',
  paymentType: 'Payment Type',
  paymentScheme: 'Payment Scheme',
  premiumBenificiary: 'Premium Benificiary',

  billingInformation: 'Billing Information',
  billingNo: 'Billing No.',
  billingStatus: 'Billing Status',
  paymentDueAt: 'Payment Due at',
  billingDetail: 'Billing Detail',

  walletStatement: 'Wallet Statement',

  billlingNo: 'Billling No.',
  comissionInformation: 'Comission Information',
  partnerCommision: 'Partner Commision',
  partnerCommisionVAT: 'Partner Commision VAT',
  partnerCommisionIncomeTax: 'Partner Commision Income Tax',
  partnerSpecialBonus: 'Partner Special Bonus',
  partnerSpecialBonusVAT: 'Partner Special Bonus VAT',
  partnerSpecialBonusIncomeTax: 'Partner Special Bonus Income Tax',

  walletDeductionInformation: 'Wallet Deduction Information',
  walletDeductionStatement: 'Wallet Deduction Statement',
  bonusDeduction: 'Bonus Deduction',
  pointDeduction: 'Point Deduction',
  finalPayableAmount: 'Final Payable Amount',
  totalPaymentAmount: 'Total Payment Amount',

  billingStatusMap: {
    1: 'Processing',
    2: 'To Be Confirmed',
    3: 'Cancelled',
    4: 'Paid'
  },
  paymentFlowMap: {
    '1': 'Pay Now',
    '0': 'Pay Later'
  },
  paymentSchemaMap: {
    1: 'Full Payment',
    2: 'Net Payment',
    3: 'Super Net Payment'
  },
  payerTypeMap: {
    1: 'Customer',
    2: 'Partner'
  },
  draft: 'Draft',

  vehicleInfo: {
    vehicleInformation: 'Vehicle Information',
    carType: 'Car Type',
    platNo: 'Plat Number',
    engineNo: 'Engine Number'
  },

  // CS-5354 印尼Live Photo更改日期
  changeDateDialog: {
    title: 'Please Change The Date',
    subtitle: 'The policy submission start time is greater than the policy start time; please update the new policy start and expiration times.',
    policyPeriod: 'Policy Period',
    startDate: 'Start Date',
    expDate: 'Expiration Date',
    cancel: 'Close',
    update: 'Update',
    year: 'Year',
    years: 'Years'
  },
  claim: {
    next: 'Next',
    back: 'Back',
    Car: 'Car',
    Moto: 'Moto',
    Travel: 'Travel',
    Property: 'Property',
    submitClaim: 'Submit Claim',
    fillInBy: 'Fill in by',
    claimSaveDraft: 'Are you sure you want to save a draft?',
    claimSaveSubmit: 'Are you sure you want to submit?',
    claimSaveInspectionDraftTip: 'You can change inspection information after save',
    claimSaveInspectionSubmitTip: 'You can not change inspection information after submission.',
    claimSaveApplicaiontDraftTip: 'You can stil change information after save',
    claimSaveApplicaiontSubmitTip: 'You can not change after submission.',
    ClaimSaveDraftSuccess: 'Save a Draft Successfully',
    ClaimSaveSubmitSuccess: 'Submit Successfully',
    Draft: 'Draft',

    propertyRulesAddress: 'Address is required',
    propertyRulesDetail: 'Address detail is required',
    propertyRulesOccupation: 'Occupation is required',

    travelRulesBirthday: 'Birthday is required',
    travelRulesFirstName: 'Fisrt Name is required',
    travelRulesLastName: 'Last Name is required',
    travelRulesPassportNo: 'Passport Numberis required',

    carRulesPlateCode: 'Plate code is required',
    carRulesPlateNo: 'Plate No. is required',
    carRulesChassisNo: 'Chassis No. is required',
    carRulesEngineNo: 'Engine No. is required',

    motoRulesPlateCode: 'Plate code is required',
    motoRulesPlateNo: 'Plate No. is required',
    motoRulesChassisNo: 'Chassis No. is required',
    motoRulesEngineNo: 'Engine No. is required',

    reporterNameRules: 'Reporter Name is required',
    reporterMobileRules: 'Reporter Mobile is required',
    reporterEmailRules: 'Reporter Email is required',

    policyNoRules: 'Policy No. is required',

    ChooseYourClaimProduct: 'Choose Your Claim Product',
    Reportby: 'Report by',
    InsuredObject: 'Insured Object',
    PolicyNoLabel: 'Policy No',
    PlateCodeLabel: 'Plate Code',
    PlateNumberLabel: 'Plate Number',
    ChassisNumberLabel: 'Chassis Number',
    EngineNumberLabel: 'Engine Number',
    BuildingsAddressLabel: 'Buildings Address',
    RiskLocationDetailAddressLabel: 'Risk Location Detail Address',
    OccupationLabel: 'Occupation',
    BirthdayLabel: 'Birthday',
    FirstNameLabel: 'First Name',
    LastNameLabel: 'Last Name',
    PassportNoLabel: 'Passport No.',
    RulesDateOfLoss: 'Date Of Lossis required',
    DateOfLossLabel: 'Date of loss',

    Period: 'Period',
    InsuredNameLabel: 'Insured Name',
    ReporterNameLabel: 'Reporter Name',
    ReporterMobileLabel: 'Reporter Mobile',
    ReporterEmailLabel: 'Reporter Email',
    ReportSubmitSuccessTitle: 'Well done! Your report has been success.',
    ReportSubmitSuccessMessage: 'Please continue to fill in the claim document.',

    ReportInformationTitle: 'Report Information',
    AddInspectionForm: 'Add Inspection Form',
    ClaimSuccessPageMainTitle: 'You have reported successfully!',
    ClaimSuccessPageSubTitle: 'We will register your claim after review',
    ClaimSuccessPageTrackingClaim: 'Tracking Claim',
    ClaimSuccessPageHomePage: 'Home Page',
    ReportIDLabel: 'Report ID',
    ReportStatusLabel: 'Report Status',
    SlipNoLabel: 'Slip No',
    ProductNameLabel: 'Product Name',
    EffectivePeriodLabel: 'Effective Period',
    ReporterInformationTitle: 'Reporter Information',
    BasicInformationTitle: 'Basic Information',
    InsuranceInformationTitle: 'Insurance Information',
    ClaimNo: 'Claim No',
    ClaimSubmissionStatusLabel: 'Claim Submission Status',
    Thankyou: 'Thank you',
    InspectionsAreSavedText: 'Inspections are saved',
    ContinueToFinishText: 'Continue to finish',
    shareMainTitle: 'Share Link for Customer Questionnaire',
    shareSubTitle: 'Please send this link to customer for easy fill up questioner',
    sendTxt: 'or you can send this link with :',
    View: 'View'
  },
  livephoto: {
    text1: 'Please adjust the vehicle position',
    text2: 'In the middle of the screen',
    turnOffrotation: 'Please turn off the automatic rotation function on your phone',
    Continue: 'Continue',
    Retake: 'Retake',
    Loading: 'Loading...',
    Back: 'Back',
    invalidTips: 'Invalid photo requires manual review which takes you a longer time',
    week1: 'Monday',
    week2: 'Tuesday',
    week3: 'Wednesday',
    week4: 'Thursday',
    week5: 'Friday',
    week6: 'Saturday',
    week0: 'Sunday'
  }
}

export default en
