import Dao from './dao'
import md5 from 'js-md5'
import { Toast } from 'vant'
import { dispatchAction } from '@/utils/dispatch'
import Router from '@/router'
import Store from '@/store'

export const getDeviceType = () => {
  let deviceType = ''
  const platform = navigator.platform.toLowerCase()
  if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
    deviceType = 'mobile'
  } else if (/mac|win|linux/i.test(platform)) {
    deviceType = 'desktop'
  } else if (/tablet|ipad/i.test(platform)) {
    deviceType = 'tablet'
  } else {
    deviceType = 'unknown'
  }
  console.log(deviceType)
  return deviceType
}

export const toastLoading = () => {
  return Toast.loading({ forbidClick: true, loadingType: 'spinner' })
}

// url 转 obj
export const urlToObj = function (querystring) {
  const searchStr = location.href.split('?')[1]
  const search = querystring || searchStr
  return search
    ? JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
      return key === '' ? value : decodeURIComponent(value)
    })
    : {}
}

// obj 转 url
export const objToUrl = function (obj) {
  return Object.keys(obj)
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    })
    .join('&')
}

/**
 * 默认 => "dd/mm/yyyy"
 * @param {time} 接受时间戳，日期
 */
export function formatDateId(time, cFormat, isoffset_GMT = true) {
  const timeZone = Dao.get('fuseSetting') ? Dao.get('fuseSetting').timeZone : 7
  const offset_GMT = new Date().getTimezoneOffset()
  if (arguments.length === 0) {
    return null
  }
  if (!time) return '-'

  const format = cFormat || '{d}/{m}/{y}'
  let date

  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time) * 1000
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    if ((typeof time === 'string') && time.indexOf('-') > -1) {
      date = new Date(time)
    } else {
      if (isoffset_GMT) {
        date = new Date(time + offset_GMT * 60 * 1000 + timeZone * 60 * 60 * 1000)
      } else {
        date = new Date(time)
      }
    }
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
    b: date.getMonth()
  }

  const time_str = format.replace(/{([ymdhisab])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][value] }
    if (key === 'b') { return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'][value] }
    return value.toString().padStart(2, '0')
  })
  return CHECK_IF_VALUE_IS_UNDEFINED(time_str) || ''
}

// 判断键值是否不为undefined
function CHECK_IF_VALUE_IS_UNDEFINED(value) {
  return value === undefined ? '-' : value
}

export function judgeClient() {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

  if (isAndroid) {
    return 'Android'
  } else if (isIOS) {
    return 'iOS'
  } else {
    return 'PC'
  }
}

export async function detectEmulation() {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true })

    const devices = await navigator.mediaDevices.enumerateDevices()

    const videoDevices = devices.filter(device => device.kind === 'videoinput')

    videoDevices.map(device => console.log(device.label))

    // const hasFrontCamera = videoDevices.some(device => device.label.toLowerCase().includes('front'))
    // const hasBackCamera = videoDevices.some(device => device.label.toLowerCase().includes('back'))

    return videoDevices.length > 1
  } catch (error) {
    return false
  }
}

/**
 * 拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
export function encodeSearchParams(obj) {
  const params = []

  Object.keys(obj).forEach((key) => {
    let value = obj[key]

    // 如果值为undefined我们将其置空
    if (typeof value === 'undefined') {
      value = ''
    }

    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join('='))
  })

  return params.join('&')
}

// 参数加盐
export function sign(data) {
  const appKey = 'fuseApp'
  const appSecret = 'fuseapp123456'
  const salt = 'fuse'

  let dataMD5 = JSON.stringify(data).replace(/{/g, '').replace(/}/g, '').replace(/,/g, '').replace(/\[/g, '').replace(/\]/g, '').replace(/:/g, '').replace(/'/g, '').replace(/'/g, '').replace(/"/g, '').replace(/"/g, '').replace(/\ +/g, '').replace(/[\r\n]/g, '')

  dataMD5 = dataMD5.toLocaleUpperCase().split('').sort().join('')

  dataMD5 = appKey + md5(appSecret) + salt + dataMD5

  return md5(dataMD5).toLocaleUpperCase()
}

// sessionStorage 封装
export const Session = {
  set: function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  get: function (key) {
    const _key = Boolean(sessionStorage.getItem(key)) && sessionStorage.getItem(key) !== 'undefined' ? sessionStorage.getItem(key) : 'null'
    return JSON.parse(_key)
  },
  remove: function (key) {
    sessionStorage.removeItem(key)
  },
  clear: function () {
    sessionStorage.clear()
  }
}

export function openWindow(url, title, w, h) {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  const left = ((width / 2) - (w / 2)) + dualScreenLeft
  const top = ((height / 2) - (h / 2)) + dualScreenTop
  const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}

export function postUrl(URL, PARAMTERS) {
  // 创建form表单
  var temp_form = document.createElement('form')
  temp_form.action = URL
  // 如需打开新窗口，form的target属性要设置为'_blank'
  // temp_form.target = "_self";
  // temp_form.target = t;
  temp_form.target = '_blank'
  temp_form.method = 'post'
  temp_form.style.display = 'none'
  // 添加参数
  for (var item in PARAMTERS) {
    var opt = document.createElement('textarea')
    opt.name = PARAMTERS[item].name
    opt.value = PARAMTERS[item].value
    temp_form.appendChild(opt)
  }
  document.body.appendChild(temp_form)
  // 提交数据
  temp_form.submit()
}

// 统一货币格式化
export function formatCurrency(num, local) {
  const currency = 'id'
  if ((!num || num == null) && num !== 0) {
    return '-'
  }
  if (num == '***') {
    return num
  }
  if (typeof num !== 'number') {
    num = Number(num)
  }
  const site = {
    'id': 'id-ID',
    'en': 'en-US',
    'zh': 'zh-HK'
  }
  const name = site[currency] || 'en-US'
  return CHECKIFVALUEISNAN(num) ? '-' : num.toLocaleString(name)
}
// 判断键值是否不为NaN
function CHECKIFVALUEISNAN(value) {
  return isNaN(value) || value === undefined || value === null
}

// base 64转码
export function encode(str) {
  // 对字符串进行编码
  var encode = encodeURI(str)
  // 对编码的字符串转化base64
  var base64 = btoa(encode)

  return base64
}

// 生成随机字符串
export const setRandomKey = () => {
  return new Date().getTime() + '_' + Math.ceil(Math.random() * 99999)
}

// 隐藏app头部
export const hideAppHeader = () => {
  // 不显示原生头部
  dispatchAction({
    method: 'showTitle',
    params: {
      show: 0
    }
  })
}

// 显示app头部
export const showAppHeader = () => {
  // 不显示原生头部
  dispatchAction({
    method: 'showTitle',
    params: {
      show: 1
    }
  })
}

const PROJECT_HOST_MAP = {
  fusepro: 'ptr',
  cashier: 'cashier'
}

const COUNTRY_HOST_MAP = {
  ID: 'co.id',
  VN: 'com.vn',
  TH: 'co.th'
}

const getRefionBasicHost = () => {
  const appInfo = Session.get('appInfo') || {}
  const { region = '' } = Router.currentRoute.query
  const finalRegion = (region || Store.state.base.region || appInfo.region).toLocaleUpperCase()
  return COUNTRY_HOST_MAP[finalRegion] || COUNTRY_HOST_MAP.VN
}

// 获取指定项目当前环境的访问域名
export const getProjectHost = (project = 'cashier') => {
  const projectHost = PROJECT_HOST_MAP[project]
  const isCashier = project == 'cashier'
  const extendPath = isCashier ? 'v2/' : ''

  if (!projectHost) { return }

  const currentEnv = process.env.VUE_APP_ENV
  const regionHost = getRefionBasicHost()

  if (currentEnv == 'development') {
    return `https://localhost:${isCashier ? 9090 : 8080}/#/`
  } else if (currentEnv == 'dev') {
    return `https://${projectHost}-dev.fusenano.com/${extendPath}#/`
  } else if (currentEnv == 'sit') {
    return `https://${projectHost}-sit.fuse.com.vn//${extendPath}#/`
  } else if (currentEnv == 'uat') {
    return `https://${projectHost}-uat.fuse.${regionHost}/${extendPath}#/`
  } else if (currentEnv == 'pre') {
    return `https://${projectHost}-pre.fuse.${regionHost}/${extendPath}#/`
  } else {
    return `https://${projectHost}.fuse.${regionHost}/${extendPath}#/`
  }
}

// 睡眠函数
export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

