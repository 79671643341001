// import enUS from 'vant/lib/locale/lang/en-US'
import _new from './modules/vn/new'

const en = {
  _new,

  // 动态表单相关
  dynamicForm: {
    uploadFail100001: 'Đã có sự cố kỹ thuật xảy ra, xin vui lòng thử lại',
    uploadFail100002: 'Dung lượng video đã vượt quá giới hạn, xin vui lòng thử lại',
    uploadFail100003: 'Đã có sự cố kỹ thuật xảy ra, xin vui lòng thử lại',
    uploadFail100004: 'Đã có sự cố kỹ thuật xảy ra, xin vui lòng thử lại',
    uploadFail100005: 'Định dạng file chưa đúng',

    uploadInfo100000: 'Loại tệp này không hỗ trợ xem trước'
  },

  vn: 'VN',
  en: 'EN',
  ok: 'Đồng ý',
  cancel: 'Huỷ bỏ',
  yes: 'Tiếp tục',
  register: {
    invalidReferralCode: 'Mã giới thiệu này không còn hiệu lực nữa',
    contactDesc: 'Vui lòng liên hệ với Fuse để được hỗ trợ mã giới thiệu mới.',
    mobileEmpty: 'Vui lòng nhập Số điện thoại.',
    mobileInvalid: 'Số điện thoại này không hợp lệ.',
    codeTipS: 'Vui lòng nhập mã gồm 4 chữ số mà chúng tôi đã gửi tới ',
    codeTipE: 'bằng SMS.',
    codeFail: 'Xin lỗi, mã xác nhận của bạn chưa được gửi thành công. Vui lòng thử lại.',
    codeEmpty: 'Mã xác minh phải có 4 chữ số.',
    codeInvalid: 'Số điện thoại hoặc mã xác nhận không chính xác.',
    nameEmpty: 'Vui lòng nhập nickname.',
    nameInvalid: 'Nickname phải là sự kết hợp của các chữ cái, số, dấu gạch dưới và dấu cách.',
    passwordEmpty: 'Hãy set mật khẩu của tài khoản bạn.',
    passwordInvalidStyle: 'Chỉ sử dụng các chữ cái, số và các ký tự đặc biệt (trừ dấu cách).',
    passwordInvalidLength: 'Vui lòng đặt mật khẩu có ít nhất 6 ký tự.',
    passwordEasy: 'Mật khẩu này quá đơn giản và dễ đoán.',
    emailInvalid: 'Vui lòng nhập đúng định dạng email.',
    send: 'Gửi',
    reSend: 'Gửi lại',

    voiceTipS: 'Chúng tôi sẽ gọi điện đến số ',
    voiceTipE: ', Vui lòng nhận điện thoại.',

    title: 'Đăng ký',
    labelMobile: 'Số điện thoại',
    labelCode: 'Mã xác nhận',
    labelName: 'Nickname',
    labelPass: 'Mật khẩu',
    voice: 'Nhận mã xác nhận bằng cuộc gọi',

    placeholderMobile: 'e.g. 77998xxxx',
    placeholderCode: 'e.g. 6128',
    placeholderName: 'e.g. Nguyễn Văn An',
    placeholderPass: 'Kết hợp 6 đến 20 ký tự gồm chữ cái và số',
    sign: 'Đăng ký',
    tip: 'Bằng việc đăng ký, bạn đã đồng ý với chúng tôi về ',
    and: 'Và',
    tc: 'Điều khoản & Điều kiện',
    ps: 'Chính sách bảo mật',
    copy: 'Fuse Tech Company Limited',

    successTit: 'Xin chúc mừng!',
    successTxt: `Bạn đã đăng ký thành công Fuse Pro và trở thành đối tác chính thức của Fuse Pro!
    Ứng dụng Fuse Pro có sẵn trong phiên bản iOS và Android. Vui lòng tải xuống và bắt đầu giao dịch!
    Cảm ơn bạn!`,
    successIOS: 'Tải xuống từ',
    successAndroid: 'Tải xuống',

    registered: 'đã đăng ký.',
    needHelp: 'Cần giúp đỡ?',
    telephone: 'Điện thoại',
    email: 'Email'
  },
  payment: {
    success: 'Hợp đồng đã được thanh toán!',
    successDesc: 'Chúc mừng bạn! Hợp đồng của bạn đã hoàn tất.',
    cancel: 'Giao dịch bị huỷ!',
    cancelDesc: 'Rất tiếc! Giao dịch này của bạn đã bị huỷ. Xin vui lòng liên hệ trực tiếp Tư vấn viên để được hỗ trợ và tư vấn thêm thông tin chi tiết.',
    validTimeTips: 'Vui lòng thanh toán đơn hàng trong thời hạn sau',
    payHint: 'Lựa chọn phương thức thanh toán?',
    bankStep1: 'Mở ứng dụng Mobile Banking hoặc Internet Banking mà bạn đang sử dụng',
    bankStep2: 'Sử dụng chức năng chuyển tiền nhanh (24/7) để chuyển khoản vào tài khoản của Fuse theo thông tin dưới đây. Lưu ý: số tài khoản sẽ thay đổi mỗi lần thanh toán. Vui lòng, đảm bảo sử dụng số tài khoản bên dưới',
    bankStep3: 'Giao dịch của bạn sẽ được xử lý trong 24 giờ làm việc. Bạn vui lòng liên hệ Fuse để được hỗ trợ thêm.',
    orderNo: 'Mã đơn hàng',
    productName: 'Sản phẩm',
    benefitPack: 'Gói quyền lợi',
    policyHolder: 'Người yêu cầu bảo hiểm',
    totalPremium: 'Tổng phí bảo hiểm',
    totalPayment: 'Số tiền thanh toán',
    policyInformation: 'Thông tin',
    changePaymentHint: 'Phương thức thanh toán lần cuối sử dụng sẽ bị hủy và không thể sử dụng nữa.',
    changePaymentTitle: 'Bạn có chắc muốn thay đổi phương thức thanh toán ?',
    bankTransferTips: 'Thực hiện các bước sau để chuyển khoản',
    payableAmount: 'Số tiền',
    bankName: 'Tên ngân hàng',
    bankAccountNumber: 'Số tài khoản ngân hàng',
    bankAccountName: 'Tên tài khoản ngân hàng',
    transferBefore: 'Chuyển tiền trước thời gian sau',
    step: 'Bước',
    premiumBenificiary: 'Công ty bảo hiểm'
  },
  successed: 'Successed',

  'pleaseSelectBenefitPackage': 'Vui lòng chọn "Gói lợi ích"',
  'productExpired': 'Sản phẩm này hết thời gian bán rồi',
  'enterAmount': 'Vui lòng nhập số tiền',
  'enterPercentage': 'Vui lòng nhập tỷ lệ phần trăm',
  'checkPlanWarning': 'Vui lòng chọn một gói',
  'benfitDetails': 'Chi tiết quyền lợi',
  'productDetails': 'Thông tin chi tiết sản phẩm',
  'productDescription': 'Thông tin sản phẩm',
  'productIntroduction': 'Giơi thiệu sản phẩm',
  'caseStudy': 'Ví dụ minh hoạ',
  'productTerms': 'Điều khoản sản phẩm',
  'productSummary': 'Tóm tắt sản phẩm',
  'claimsDetails': 'Quy trình YCBT',
  'faq': 'Câu hỏi thường gặp',
  'tipsForPurchase': 'Mẹo mua hàng',
  'consentStatement': 'Tuyên bố đồng ý',
  'contentsOfDsclaimer': 'Nội dung tuyên bố từ chối trách nhiệm',
  'details': 'Thông tin bảo hiểm',
  premiumDetails: 'Chi tiết phí bảo hiểm',
  mainInformation: 'Thông tin chính',
  'insuranceInformation': 'Thông tin bảo hiểm',
  'benefitPackage': 'Gói bảo hiểm',
  'policyAmount': 'Chi tiết phí',
  'benefitPlan': 'Kế hoạch lợi ích',
  'benefitPlans': 'Gói quyền lợi',
  'adminFee': 'Phí quản trị',
  'serviceFee': 'Phí dịch vụ',
  'bonusAmount': 'Tiền thưởng nhận được',
  'incomeTaxofBonusDeduction': 'Thuế TNCN',
  'subTotalFee': 'Phí tổng phụ',
  'discount': 'Chiết khấu',
  'total': 'Tổng tiền',
  bottTotal: 'Tổng phí bảo hiểm',
  'moreDetails': 'Thêm chi tiết',
  'buyNow': 'Mua ngay',
  'confirm': 'Xác nhận',
  'benfitPackageDetails': 'Chi tiết gói quyền lợi',
  'coverage': 'Phạm vi bảo hiểm',
  'benefit': 'Quyền lợi bảo hiểm',
  'amount': 'Số lượng',
  'insuranceDetails': 'Thông tin bảo hiểm',
  'productName': 'Sản phẩm',
  'policyPeriod': 'Hiệu lực hợp đồng',
  'startDate': 'Ngày hiệu lực',
  'endDate': 'Ngày hết hạn',
  'policyDiscount': 'Chính sách giảm giá',
  'close': 'Hủy bỏ',
  'subTotal': 'tổng phụ',
  'enterDiscount': 'nhập chiết khấu',
  'pleaseEnterDiscountRate': 'Vui lòng nhập số tiền của tỷ lệ chiết khấu chính sách đặt hàng này',
  'insuredAmount': 'Số tiền bảo hiểm',
  'totalInsurePerson': 'Tổng số người được bảo hiểm',
  'coverageDiscount': 'Giảm giá bảo hiểm',
  'pleaseEnterCoverageDiscountRate': 'Vui lòng nhập số tiền của tỷ lệ chiết khấu bảo hiểm này',
  'seeDetails': 'Thông tin phí bảo hiểm',
  'proofToast': 'Bạn không thể tải lên bằng chứng thanh toán nữa vì thời hạn thanh toán của đơn đặt hàng đã hết hạn.',

  order: {
    statusMap: {
      1: 'Đang xử lý', // 正在处理中
      2: 'Hoàn tất', // 完成，当订单中所有投保单状态变成终态或生成保单，则订单状态变为Completed
      3: 'Bị từ chối'
    }
  },

  'policy': {
    'details': {
      'orderId': 'ID đặt hàng',
      'orderNo': 'Mã đơn hàng',
      'policyNumber': 'Số HĐBH',
      'copy': 'Sao chép',
      'copyToast': 'Sao chép thành công',
      'status': 'Trạng thái đơn hàng',
      'statusMap': {
        1: 'Chưa hiệu lực',
        2: 'Có hiệu lực',
        3: 'Hết hiệu lực',
        4: 'Đã hủy'
      },
      'slipStatusMap': {
        1: 'Đang xử lý',
        2: 'Đang thẩm định',
        3: 'Đã được duyệt',
        4: 'Bị từ chối'
      },
      'paymentStatus': 'Trạng thái thanh toán',
      'paymentStatusMap': {
        1: 'Chưa thanh toán',
        2: 'Đã thanh toán',
        3: 'Đã hủy'
      },
      'insuranceCompanyFive': 'Công ty Bảo hiểm Năm',
      'seeDetails': 'Thông tin phí bảo hiểm',
      viewDetails: 'Xem chi tiết',
      'coveragePeriod': 'Hiệu lực hợp đồng',
      'orderTime': 'Thời gian YCBH',
      'paymentTime': 'Thời gian thanh toán',
      'benefitDetails': 'Chi tiết quyền lợi',
      'claimdetails': 'Quy trình yêu cầu bồi thường',
      'claimdetailsBtn': 'Quy trình YCBT',
      'payNow': 'Trả tiền ngay'
    },
    'seeDetails': {
      'toastTotalPremium': 'Tiền thưởng của đối tác sẽ được tính trên phí bảo hiểm chưa bao gồm 10% VAT và khấu trừ phí dịch vụ nếu có.',
      'totalPremium': 'Tổng phí bảo hiểm',
      'insuranceCompanyFive': 'Công ty Bảo hiểm Năm',
      'effectiveDate': 'Ngày hiệu lực',
      'expirationDate': 'Ngày hết hạn',
      'premiumDetail': 'Chi tiết',
      'Insurance': 'Phí bảo hiểm',
      'maincoverage': 'Quyền lợi chính',
      'additionalCoverage': 'Quyền lợi bổ sung',
      'serviceFee': 'Phí dịch vụ',
      'bonusAmount': 'Tiền thưởng nhận được',
      'couponPoints': 'Điểm ưu đãi',
      'incomeTaxofBonusDeduction': 'Thuế TNCN',
      'receivedBonus': 'Tiền thưởng nhận được',
      'adminFee': 'Phí quản trị',
      'total': 'TỔNG PHÍ',
      instantService: 'Dịch vụ nhanh',
      quickClaim: 'Bồi thường nhanh',
      fullCoverage: 'Toàn bộ bảo hiểm',
      'couponApplied': 'Đã áp dụng ưu đãi',
      'bonus': 'Tiền thưởng',
      'paymentAmount': 'Số tiền thanh toán',
      'fusePoints': 'Fuse Points',
      'extraPoints': 'Điểm thưởng thêm',
      'rewardPoints': 'Điểm ưu đãi',
      'supportAmountFromExtraPoints': 'Hỗ trợ từ điểm thưởng thêm',
      'receivedFusePoints': 'Fuse Point nhận được'
    }
  },
  insuranceCompany: 'Công ty bảo hiểm',
  paymentAmount: 'Số tiền thanh toán',
  coupon: 'Ưu đãi',
  couponAdd: 'Thêm ưu đãi',
  couponAvailable: 'Mã ưu đãi khả dụng',
  packageDetail: 'Tổng quan',
  description: 'Chi tiết sản phẩm',
  benefitPackages: 'Gói phúc lợi',
  whichPackage: 'Bạn muốn thao tác những gói bảo hiểm nào?',
  coSel: ' quyền lợi đã được chọn',
  benefitPackDetails: 'Chi tiết quyền lợi',
  plan: 'Phương án',
  chooseExtendedCoverage: 'Lựa chọn các quyền lợi bổ sung (nếu có)',
  coverInAm: 'Số tiền bồi thường bảo hiểm',
  and: 'và',
  otherBenefit: 'các phúc lợi khác',
  premium: 'Phí bảo hiểm',
  personalIncomeTax: 'Thuế TNCN',
  extraPoints: 'Điểm thưởng thêm',
  rewardPoints: 'Điểm ưu đãi',
  supportAmountFromExtraPoints: 'Hỗ trợ từ điểm thưởng thêm',
  receivedFusePoints: 'Fuse Point nhận được',
  earnedBonus: 'Tiền thưởng nhận được',
  receivedBonus: 'Tiền thưởng nhận được',
  mainCover: 'Quyền lợi chính',
  addiCover: 'Quyền lợi bổ sung',
  viewDetail: 'Xem chi tiết',
  TotalInPer: 'Số người được bảo hiểm',
  DeducAm: 'Số tiền khấu trừ',
  premSum: 'Tóm tắt phí bảo hiểm',
  premDetails: 'Chi tiết phí bảo hiểm',
  more: 'Xem thêm',
  clickHere: 'Ấn vào đây để xem gói đã chọn',
  deductiAmount: 'Số tiền khấu trừ',
  aNTotal: 'Tổng phí',
  premiumExcludeVat: 'Phí bảo hiểm (chưa bao gồm VAT)',
  premiumIncludeVat: 'Phí bảo hiểm (đã bao gồm VAT)',
  proof: 'Sao kê',
  marketablePro: 'Tên sản phẩm',
  benefitPack: 'Gói quyền lợi',
  orderTime: 'Thời gian YCBH',
  policyNumber: 'Số HĐBH',
  policyHolder: 'Người được bảo hiểm',
  insuranceSlipNumber: 'Số HĐBH tạm thời',

  next: 'Tiếp tục',
  payNow: 'Trả tiền ngay',
  payLater: 'Trả tiền sau',
  select: 'lựa chọn',
  pay: 'Thanh toán',
  month: 'tháng',

  termsAndConditions: 'Bảng báo giá này được lập bởi công ty TNHH Fuse Online(“Công Ty”) bằng hình thức điện tử. Đối tác phải cung cấp Bảng báo giá này cho bên mua bảo hiểm. Nếu có những thông tin quan trọng hoặc thắc mắc từ bên mua bảo hiểm liên quan đến Bảng báo giá này, đối tác phải thông báo câu hỏi đó ngay lập tức cho Công Ty.',
  pricing: {
    newPrecautions: 'Thông tin về thu nhập chỉ là tham khảo và có thể thay đổi trước khi quá trình thanh toán hoàn tất, dẫn đến sự chênh lệch trong giá trị thực lãnh.',
    precautions: 'Tiền thưởng bạn thực nhận có thể khác số này trong một số trường hợp nhất định.',
    'bonus': 'Tiền thưởng',
    'commission': 'Tiền thưởng',
    'premiumDetail': 'Chi tiết phí bảo hiểm',
    'Premium': 'Phí bảo hiểm',
    'adminFee': 'Phí quản trị',
    'serviceFee': 'Phí dịch vụ',
    'policyAmount': 'Tổng phí',
    'close': 'Đóng',
    'pit': 'Thuế TNCN',
    'receivedCommission': 'Tiền thưởng nhận được',
    'cancel': 'Hủy bỏ',
    'apply': 'Áp dụng',
    'extraPoints': 'Điểm thưởng thêm',
    'rewardPoints': 'Điểm ưu đãi',
    'supportAmountFromExtraPoints': 'Hỗ trợ từ điểm thưởng thêm',
    'receivedFusePoints': 'Fuse Point nhận được',
    'paymentAmountDetail': 'Chi tiết',
    'paymentAmount': 'Số tiền thanh toán'
  },

  pricingList: {
    describe: 'Rất tiếc không có kết quả tìm kiếm',
    guide: 'Quay lại trang trước và thử lại, hoặc liên hệ CSKH 028 7300 8803'
  },
  comparisonList: {
    describe: 'Chưa có bảng so sánh sảnh phẩm'
  },

  decline: 'Từ chối',
  proceed: 'Tiếp tục',
  expiryDate: 'Ngày hết hạn',
  validUntil: 'Ngày hết hạn',
  quoteNo: 'Mã báo giá',
  quote: 'Danh sách bảng báo giá',
  createdTime: 'Thời gian yêu cầu',
  rfQNo: 'Mã yêu cầu',
  packageName: 'Gói sản phẩm',
  generalInformation: 'Thông tin yêu cầu',
  rfqStatus: 'Trạng thái yêu cầu',

  quoteDecline: {
    declineTheQuote: 'Từ chối',
    tips: 'Vui lòng chọn lý do từ chối bảng báo giá',
    reason: {
      1: 'Thay đổi ý định',
      2: 'Thông tin nhập không chính xác',
      3: 'Giá không hấp dẫn'
    },
    rulesMessage: 'Vui lòng chọn lý do',
    submitting: 'đệ trình...'
  },
  submit: 'Nộp',
  declineReason: {
    1: 'Thay đổi ý định',
    2: 'Thông tin nhập không chính xác',
    3: 'Giá không hấp dẫn',
    4: 'Hết hạn'
  },

  quoteDetails: {
    validTimeTips: 'Bảng báo giá này sẽ hết hạn sau: ',
    validTimeExpiredTips: 'Báo giá đã hết hạn',
    validTimeDeclinedTips: 'Báo giá bị từ chối',
    validTimeAcceptedTips: 'Báo giá được chấp nhận',
    insuranceCompany: 'Công ty bảo hiểm',
    productName: 'Sản phẩm',
    quoteNo: 'Mã báo giá',
    status: 'Trạng thái',
    days: 'Ngày',
    hours: 'Giờ',
    minutes: 'Phút',
    seconds: 'Giây',
    declinedReason: 'Lý do từ chối',
    declined: 'Hết hạn',
    feeDetail: 'Chi tiết phí'
  },
  processing: 'Đang xử lý',
  quoted: 'Đã báo giá',
  declined: 'Bị từ chối',
  toBeConfirmed: 'Hoàn tất',
  confirmed: 'Đã xác nhận',
  expired: 'Hết hạn',
  selectedCoverages: 'Quyền lợi đã chọn',
  totalPolicyAmount: 'Tổng phí',
  needMoreData: 'Cần thêm dữ liệu',

  overView: {
    productOver: 'Tổng quan',
    claimDetails: 'Thông tin khác',
    productDescription: 'Thông tin sản phẩm',
    productPlans: 'Gói bảo hiểm',
    otherInformation: 'Thông tin khác',
    claimProcedures: 'Hướng dẫn yêu cầu bồi thường',
    claimDocuments: 'Hồ sơ yêu cầu bồi thường'
  },

  days: 'Ngày',
  hours: 'Giờ',
  minutes: 'Phút',
  seconds: 'Giây',
  orderNo: 'Mã đơn hàng.',
  paymentDue: 'Thời hạn thanh toán đơn hàng',
  informationCompletion: 'Hoàn thành thông tin',
  completeInfoTitle: 'Thông tin đánh giá rủi ro được hoàn thành!',
  completeInfoDesc: 'Chúc mừng bạn đã hoàn thành tất cả các thông tin đánh giá rủi ro. Vui lòng yêu cầu tư vấn viên của bạn thanh toán để hoàn tất hợp đồng bảo hiểm.',
  completeHealthTitle: 'Bảng câu hỏi sức khỏe đã được hoàn tất!',
  completeHealthDesc: 'Chúc mừng bạn đã hoàn thành bảng câu hỏi sức khỏe. Vui lòng yêu cầu tư vấn viên của bạn thanh toán để hoàn tất hợp đồng bảo hiểm.',
  expireTitle: 'Liên kết đã hết hạn.',
  expireDesc: 'Vui lòng yêu cầu tư vấn viên gửi lại liên kết thông tin đánh giá rủi ro',
  policyInformation: 'Thông tin',
  submitInspectionInformation: 'Xác nhận thông tin đánh giá rủi ro',
  verificationCode: 'Mã xác nhận',
  askPartnerPay: 'Gửi đối tác',
  complete: 'Hoàn tất',
  otpError: 'Mã xác thực không chính xác',
  otpDesc: `Vui lòng nhập mã gồm 4 chữ số mà chúng tôi đã gửi tới {mobileNo} bằng SMS.`,
  send: 'Gửi OTP',
  viewInspectionInformation: 'Xem lại thông tin đánh giá rủi ro',
  needOTPDesc: `Mẫu thông tin đánh giá rủi ro này có yêu cầu mã xác thực. Vui lòng đảm bảo rằng số điện thoại di động của bạn trùng khớp với số điện thoại di động của {mobileNo}.`,
  submitInspection: `Tiếp tục`,
  invalidPlatform: 'Yêu cầu chụp ảnh trực tiếp bằng điện thoại hoặc quyền truy cập máy ảnh đã bị tắt.',
  inputVerification: 'Vui lòng nhập mã xác nhận',
  no: 'Đóng',

  // ...enUS
  meta: {
    'Quotation Form': 'Báo giá',
    'Pricing Options': 'Lựa chọn giá',
    'Pricing Details': 'Chi tiết giá',
    'Pricing Application': 'Mẫu đơn báo giá',
    'International Travel': 'Thông tin sản phẩm',
    'General Information': 'Tóm tắt thông tin',
    'Quote Details': 'Chi tiết báo giá',
    'Policy Details': 'Thông tin bảo hiểm',
    'Order Details': 'Thông tin bảo hiểm',
    'Benefit Claim Detail': 'Chi tiết Quyền lợi & YCBT',
    'Purchase': 'Thông tin bảo hiểm',
    'Partnership': 'Quan hệ đối tác',
    'Performance': 'Thành tích',
    'Direct Invited Downline': 'Tuyến dưới trực tiếp',
    'Negotiation Request': 'Yêu cầu đàm phán',
    'Comparison List': 'Danh sách bản so sánh',
    'Product Compare': 'So sánh giá',
    'Add Inspection Info': 'Thêm thông tin thẩm định',
    'Vehicle Photos for Inspection': 'Hình ảnh xe để kiểm tra',
    'Additional Information': 'thông tin thêm',
    'Request For Quote': 'Thông tin báo giá',
    'Attachment Files': 'Tài liệu đính kèm'
  },
  pricingDetails: 'Chi tiết giá',

  partnershipAgreeVN: 'Tôi xác nhận đã đọc toàn bộ nội dung Thỏa Thuận, <a href="https://static.fuse.com.vn/xml/GeneralTerm.pdf">Điều khoản chung</a>  đính kèm và đồng ý giao kết Thỏa Thuận Hợp Tác với công ty TNHH Fuse Online.',

  placeholderSearch: 'Vui lòng nhập từ khóa tìm kiếm',
  bankTransfer: 'Chuyển khoản ngân hàng',
  province: 'Tỉnh/Thành phố',
  city: 'Quận/Huyện',
  district: 'Phường/Xã',
  quotedPrice: 'Giá trích dẫn',
  benefitAndClaim: 'Chi tiết Quyền lợi & YCBT',
  slipNo: 'Số HĐBH tạm thời',
  policyNo: 'Số HĐBH',
  coveragePeriod: 'Hiệu lực hợp đồng',
  theInsured: 'Người được bảo hiểm',
  express: 'Cấp nhanh',
  expressTip: 'Hợp đồng của bạn sẽ được ưu tiên xử lý nhanh, kể cả cuối tuần và ngày nghỉ lễ.',
  loading: 'Đang tải...',
  paymentCountDownTip: 'Vui lòng hoàn tất thông tin đánh giá rủi ro và thanh toán đơn hàng trong thời hạn sau',

  rebate: 'Khoản hỗ trợ',
  discountFromInsco: 'Chiết khấu từ CTBH',
  discountToCustomer: 'Hỗ trợ từ Tiền thưởng',
  information: 'Chi tiết',
  rebateTip: 'Khoản hỗ trợ sẽ được khẩu trừ vào Thu nhập của bạn cho đơn hàng này, nếu bạn chọn tùy chọn này, Thu nhập của bạn cho đơn hàng này sẽ bị giảm trừ.',
  rebateUpTo: 'Khoản hỗ trợ từ Tiền thưởng lên đến',
  rebateCfgTit: 'Khoản hỗ trợ',
  rebatePlaceholder: 'Bạn muốn hỗ trợ bao nhiêu',
  maximumDiscount: 'Khoản hỗ trợ tối đa',
  requiredKYC: 'Cần xác thực',
  productFound: 'sản phẩm được tìm thấy',
  SelectThePackage: 'Chọn gói sản phẩm',
  comparisonCode: 'Mã so sánh',
  saveComparison: 'Lưu',
  selectPlan: 'Mua',
  filter: 'Lọc',
  applyFilters: 'Áp dụng',
  reset: 'Xóa bộ lọc',
  quoteNumber: 'Mã yêu cầu',
  quoteDate: 'Ngày báo giá',
  clearAll: 'Xóa',
  compare: 'So sánh',
  all: 'tất cả',
  disclaimer: 'Người được bảo hiểm cần phải đọc toàn bộ các điều khoản trong Bản chào phí này và gửi yêu cầu tiến hành việc cấp Hợp Đồng Bảo Hiểm khi và chỉ khi đã đồng ý với toàn bộ các đề nghị trong Bản chào phí. Nếu chúng tôi nhận được yêu cầu tiến hành cấp Hợp Đồng Bảo Hiểm, chúng tôi mặc nhiên xem là Người được bảo hiểm đã hiểu rõ và chấp thuận toàn bộ đề nghị nêu trong Bản chào phí.<br/><br/>Báo giá này có hiệu lực đến ngày [expiry_date]. Nếu có sự khác biệt giữa các quy định trong Hợp Đồng Bảo Hiểm và Bản chào phí, các quy định trong Hợp Đồng Bảo Hiểm sẽ được ưu tiên áp dụng.<br/><br/>Nếu khách hàng có bất kỳ câu hỏi nào liên quan đến Bản chào phí, vui lòng liên hệ với chúng tôi qua email <a href="mailto:cs@fuse.com.vn" rel="nofollow">cs@fuse.com.vn</a> hoặc gọi Hotline (028) 7300 8803.',
  quotation: 'BẢN CHÀO PHÍ',
  customerName: 'Tên Khách hàng',
  brandModel: 'Hãng xe/Phiên bản',
  manufacturerYear: 'Năm sản xuất',
  registerDate: 'Ngày đăng ký lần đầu',
  vehicleType: 'Loại xe',
  usage: 'Mục đích sử dụng',
  seatCapacity: 'Số chỗ ngồi',
  carValue: 'Giá trị xe/Số tiền bảo hiểm',
  fromDate: 'Từ ngày',
  toDate: 'Đến ngày',
  quotationInformation: 'THÔNG TIN BÁO GIÁ',
  disclaimerTitle: 'ĐIỀU KHOẢN CHẤP THUẬN TOÀN BỘ',
  updateComparison: 'Cập nhật',
  premiumInclVat: 'Phí bảo hiểm đã bao gồm VAT',
  allOption: 'Tất cả',
  priceRange1: ' Dưới 2,000,000 VND',
  priceRange2: 'Trên 4,000,000 - 6,000,000 VND',
  priceRange3: 'Trên 6,000,0000 VND',
  page: 'Trang',
  requestNumber: 'Mã yêu cầu',
  currency: 'Đơn vị tiền tệ',
  coveragePeriodUnit: {
    unit1: 'Ngày',
    unit2: 'Tháng',
    unit3: 'Năm'
  },
  dutybenefit: {
    unit2: 'Ngày',
    unit3: 'Năm',
    unit4: 'Disability',
    unit5: 'Confinement',
    unit6: 'Lần khám thai',
    unit7: 'Lần',
    unit8: 'Người'
  },
  maincoverage: 'Quyền lợi chính',

  downline: {
    totalGwp: 'Tổng phí',
    totalBonus: 'Tổng tiền thưởng',
    totalPoints: 'Tổng Fuse Point',
    totalPolicy: 'Tổng số hợp đồng',
    ByProductCategory: 'Theo nhóm sản phẩm',
    premium: 'Phí bảo hiểm',
    policy: 'Số hợp đồng',
    ByInsuranceCompany: 'Theo công ty bảo hiểm',

    // date
    Updatedon: 'Cập nhật ngày',
    Month: 'Tháng',
    Quarter: 'Quý',
    Year: 'Năm',

    // no order: ''
    noOrder: 'Không có đơn hàng trong kỳ',
    maxLv: 'Xin chúc mừng! Bạn đang ở cấp độ cao nhất.',
    InviteFriends: 'Giới thiệu bạn bè',
    noPromission: 'Bạn không thể xem tuyến dưới của thành viên có thứ hạng lớn hơn hoặc ngang bằng bạn',

    // myteam
    Myself: 'Cá nhân',
    MyTeam: 'Đội ngũ của tôi',
    NextRank: 'Hạng tiếp theo',
    InvitationTree: 'Cây tuyển dụng',
    TotalInvitedDownline: 'Tổng số tuyến dưới',
    NewInvitedDownline: 'Tuyến dưới mới',
    InvitedTransactingDownline: 'Tuyến dưới có giao dịch',
    DirectInvitedDownline: 'Tuyến dưới trực tiếp',

    Branch: 'Đội ngũ',
    TotalBranchDownline: 'Tổng số đội ngũ ',
    NewBranchDownline: 'Đội ngũ mới ',
    BranchTransactingDownline: 'Đội ngũ mới có giao dịch',
    BranchGWP: 'Tổng phí đội ngũ',
    BranchPolicy: 'Số hợp đồng của đội ngũ',

    Contributing: 'Đội ngũ tạo FP',
    PassiveIncome: 'Thu nhập thụ động',
    UplinePoint: 'Thưởng từ tuyến dưới',
    PMPoint: 'Thưởng PM',
    PDPoint: 'Thưởng PD',
    PMBreakupPoint: 'Thưởng hỗ trợ PM',
    PDBreakupPoint: 'Thưởng hỗ trợ PD',
    TotalContributingDownline: 'Tổng số đội ngũ tạo FP',
    NewContributingDownline: 'Đội ngũ mới tạo FP',
    ContributingGWP: 'Tổng phí tạo FP',
    ContributingPolicy: 'Số HĐ tạo FP',

    JoinedSince: 'Tham gia kể từ ngày',
    DirectDownline: 'Tuyến dưới trực tiếp',

    PersonalContributingGWP: 'Số HĐ cá nhân + đội ngũ',
    PersonalPolicy: 'Số hợp đồng cá nhân'
  },
  percentage: {
    text: `Bạn đã đạt được {name} để thăng cấp`
  },
  // nextRank
  'GWP(Branch)': 'Tổng phí đội ngũ',
  'GWP(Individual)': 'Tổng phí cá nhân',
  'Branch RP': 'Đội ngũ tuyến dưới',
  'Branch TP': 'Đội ngũ tuyến dưới có giao dịch',

  negotiable: {
    negotiableTit1: 'Bạn có đề nghị tốt hơn? ',
    negotiableTit2: 'Chúng tôi sẽ đề nghị giá mới',

    negotiationQuoteStatus1: 'Đang thương lượng',
    negotiationQuoteStatus2: 'Đồng ý thay đổi giá',
    negotiationQuoteStatus3: 'Từ chối thay đổi giá'
  },

  // 后台接口特定错误吗提示
  '200200002': 'Rất tiếc! Bạn đã thương lượng quá 3 lần cho phép! Cùng xem xét lại những đề xuất trước đó và đưa ra quyết định nhé! Đóng!',

  // Inspection 相关
  later: 'Thực hiện sau',
  applicationConfirmInspectionTit: 'Bạn muốn tiếp tục nhập thông tin thẩm định?',
  applicationConfirmInspectionText: 'Bạn chưa hoàn tất thông tin thẩm định. Đây là những thông tin quan trọng để xuất hợp đồng bảo hiểm.',
  inspectionRequired: 'Yêu cầu thông tin đánh giá rủi ro',
  inspectionRejected: 'Thông tin thẩm định bị từ chối',

  confirmSubmit: 'Bạn không thể thay đổi thông tin sau khi gửi.',
  confirmDraft: 'Are you sure you want to save as draft?',
  inspectionSubmitTip: 'Bạn có muốn nộp thông tin đánh giá rủi ro ?',
  inspectionInformation: 'Thông tin đánh giá rủi ro',

  // Non Binder
  success: {
    title: 'Gửi yêu cầu thành công!',
    desc: 'Báo giá của bạn đang được xử lý và sẽ được gửi đến bạn trong thời gian sớm nhất. Cảm ơn bạn đã sử dụng Fuse Pro!'
  },
  backToHome: 'Về trang chủ',
  viewQuote: 'Xem chi tiết',
  quotes: 'Trích dẫn',
  quoteProcessing: {
    title: 'Đang xử lý',
    desc: 'Báo giá của bạn đang được xử lý và sẽ được gửi đến bạn trong thời gian sớm nhất. Cảm ơn bạn đã sử dụng Fuse Pro!'
  },

  attachmentFiles: 'Tài liệu đính kèm',

  quoteNeedMoreData: {
    title: 'Bổ sung thông tin!',
    desc: 'Vui lòng bổ sung thêm thông tin để sớm nhận được báo giá tốt nhất!'
  },

  cancelRequest: 'Hủy yêu cầu',

  nonBinderQuoteDecline: {
    title: 'Bạn có thật sử muốn từ chối bảng báo giá?',
    subtitle: 'Chọn lý do từ chối'
  },

  nonBinderRfqDecline: {
    title: 'Bạn có chắc chắn muốn hủy RFQ không?',
    subtitle: 'Chọn lý do từ chối'
  },

  // Binder Renew 相关
  renewable: {
    renewableTit1: 'Không có sản phẩm bạn đang tìm? ',
    renewableTit2: 'Đề xuất tại đây nhé!'
  },

  popupYes: 'Đồng ý',
  popupNo: 'Không',

  paymentInformation: 'Thông tin thanh toán',
  insuredName: 'Tên người được bảo hiểm',
  paymentFlow: 'Loại hình thanh toán',
  paymentType: 'Phương thức thanh toán',
  paymentScheme: 'Cơ chế thanh toán',
  premiumBenificiary: 'Công ty bảo hiểm',

  billingInformation: 'Thông tin hóa đơn',
  billingNo: 'Mã hóa đơn',
  billingStatus: 'Trạng thái hóa đơn',
  paymentDueAt: 'Ngày hết hạn thanh toán',
  billingDetail: 'Chi tiết hóa đơn',

  walletStatement: 'Sao kê Ví Fuse của tôi',

  billlingNo: 'Mã hóa đơn',
  comissionInformation: 'Thông tin tiền thưởng',
  partnerCommision: 'Tiền Thưởng',
  partnerCommisionVAT: 'Thuế VAT Tiền Thưởng',
  partnerCommisionIncomeTax: 'Thuế TNCN Tiền Thưởng',
  partnerSpecialBonus: 'Điểm Thưởng Thêm',
  partnerSpecialBonusVAT: 'Thuế VAT Điểm Thưởng Thêm',
  partnerSpecialBonusIncomeTax: 'Thuế TNCN Điểm Thưởng Thêm',

  walletDeductionInformation: 'Thông tin cấn trừ từ Ví Fuse',
  walletDeductionStatement: 'Trạng thái thanh toán',
  bonusDeduction: 'Cấn trừ tiền thưởng',
  pointDeduction: 'Cấn trừ điểm thưởng/ Điểm ưu đãi',
  finalPayableAmount: 'Số tiền thanh toán',
  totalPaymentAmount: 'Tạm tính',
  payableAmount: 'Tổng phí',

  billingStatusMap: {
    1: 'Đang xử lý',
    2: 'Đang chờ xác nhận',
    3: 'Đã hủy',
    4: 'Đã thanh toán'
  },
  paymentFlowMap: {
    '1': 'Thanh toán ngay',
    '0': 'Thanh toán sau'
  },
  paymentSchemaMap: {
    1: 'Thanh toán toàn bộ',
    2: 'Cấn trừ tiền thưởng',
    3: 'Cấn trừ tiền thưởng'
  },
  payerTypeMap: {
    1: 'Khách hàng',
    2: 'Đối tác'
  },
  draft: 'Draft',
  claim: {
    next: 'Next',
    back: 'Back',
    Car: 'Car',
    Moto: 'Moto',
    Travel: 'Travel',
    Property: 'Property',
    submitClaim: 'Submit Claim',
    fillInBy: 'Fill in by',
    claimSaveDraft: 'Are you sure you want to save a draft?',
    claimSaveSubmit: 'Are you sure you want to submit?',
    claimSaveInspectionDraftTip: 'You can change inspection information after save',
    claimSaveInspectionSubmitTip: 'You can not change inspection information after submission.',
    claimSaveApplicaiontDraftTip: 'You can stil change information after save',
    claimSaveApplicaiontSubmitTip: 'You can not change after submission.',
    ClaimSaveDraftSuccess: 'Save a Draft Successfully',
    ClaimSaveSubmitSuccess: 'Submit Successfully',
    Draft: 'Draft',

    propertyRulesAddress: 'Address is required',
    propertyRulesDetail: 'Address detail is required',
    propertyRulesOccupation: 'Occupation is required',

    travelRulesBirthday: 'Birthday is required',
    travelRulesFirstName: 'Fisrt Name is required',
    travelRulesLastName: 'Last Name is required',
    travelRulesPassportNo: 'Passport Numberis required',

    carRulesPlateCode: 'Plate code is required',
    carRulesPlateNo: 'Plate No. is required',
    carRulesChassisNo: 'Chassis No. is required',
    carRulesEngineNo: 'Engine No. is required',

    motoRulesPlateCode: 'Plate code is required',
    motoRulesPlateNo: 'Plate No. is required',
    motoRulesChassisNo: 'Chassis No. is required',
    motoRulesEngineNo: 'Engine No. is required',

    reporterNameRules: 'Reporter Name is required',
    reporterMobileRules: 'Reporter Mobile is required',
    reporterEmailRules: 'Reporter Email is required',

    policyNoRules: 'Policy No. is required',

    ChooseYourClaimProduct: 'Choose Your Claim Product',
    Reportby: 'Report by',
    InsuredObject: 'Insured Object',
    PolicyNoLabel: 'Policy No',
    PlateCodeLabel: 'Plate Code',
    PlateNumberLabel: 'Plate Number',
    ChassisNumberLabel: 'Chassis Number',
    EngineNumberLabel: 'Engine Number',
    BuildingsAddressLabel: 'Buildings Address',
    RiskLocationDetailAddressLabel: 'Risk Location Detail Address',
    OccupationLabel: 'Occupation',
    BirthdayLabel: 'Birthday',
    FirstNameLabel: 'First Name',
    LastNameLabel: 'Last Name',
    PassportNoLabel: 'Passport No.',
    RulesDateOfLoss: 'Date Of Lossis required',
    DateOfLossLabel: 'Date of loss',

    Period: 'Period',
    InsuredNameLabel: 'Insured Name',
    ReporterNameLabel: 'Reporter Name',
    ReporterMobileLabel: 'Reporter Mobile',
    ReporterEmailLabel: 'Reporter Email',
    ReportSubmitSuccessTitle: 'Well done! Your report has been success.',
    ReportSubmitSuccessMessage: 'Please continue to fill in the claim document.',

    ReportInformationTitle: 'Report Information',
    AddInspectionForm: 'Add Inspection Form',
    ClaimSuccessPageMainTitle: 'You have reported successfully!',
    ClaimSuccessPageSubTitle: 'We will register your claim after review',
    ClaimSuccessPageTrackingClaim: 'Tracking Claim',
    ClaimSuccessPageHomePage: 'Home Page',
    ReportIDLabel: 'Report ID',
    ReportStatusLabel: 'Report Status',
    SlipNoLabel: 'Slip No',
    ProductNameLabel: 'Product Name',
    EffectivePeriodLabel: 'Effective Period',
    ReporterInformationTitle: 'Reporter Information',
    BasicInformationTitle: 'Basic Information',
    InsuranceInformationTitle: 'Insurance Information',
    ClaimNo: 'Claim No',
    ClaimSubmissionStatusLabel: 'Claim Submission Status',
    Thankyou: 'Thank you',
    InspectionsAreSavedText: 'Inspections are saved',
    ContinueToFinishText: 'Continue to finish',
    shareMainTitle: 'Share Link for Customer Questionnaire',
    shareSubTitle: 'Please send this link to customer for easy fill up questioner',
    sendTxt: 'or you can send this link with :',
    View: 'xem'
  },
  livephoto: {
    text1: 'Vui lòng điều chỉnh vị trí xe',
    text2: 'ở giữa màn hình',
    turnOffrotation: 'Vui lòng tắt chức năng xoay màn hình tự động trên điện thoại của bạn',
    Continue: 'Tiếp tục',
    Retake: 'Chụp lại',
    Loading: 'Đang tải...',
    Back: 'Đã hiểu',
    invalidTips: 'Ảnh không hợp lệ cần phải xem xét thủ công, mất nhiều thời gian hơn',
    week1: 'Thứ Hai',
    week2: 'Thứ Ba',
    week3: 'Thứ Tư',
    week4: 'Thứ Năm',
    week5: 'Thứ Sáu',
    week6: 'Thứ Bảy',
    week0: 'Chủ Nhật'
  }

}

export default en
