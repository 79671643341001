/**
 * @Author:       huangjiaquan
 * @Date:         2023-06-12 14:51
 * @LastEditTime: 2023-06-12 14:51
 * @LastEditors:  huangjiaquan
 * @FilePath:     scm-web/src/views/test/utils
 */
/**
 * @Author:       huangjiaquan
 * @Date:         2023-06-12 14:32
 * @LastEditTime: 2023-06-12 14:32
 * @LastEditors:  huangjiaquan
 * @FilePath:     scm-web/src/views/test
 */
import { inputFormatter } from './inputFormatter'
// import { debounce } from '@fu/core'
// import { Format } from './format'

// function bindFormatFunc(el, binding, vnode) {
//   let value = vnode.text
//   if (vnode.data && vnode.data.domProps && vnode.data.domProps.innerHTML) {
//     value = vnode.data.domProps.innerHTML
//   }
//   if (vnode.children && vnode.children.length === 1) {
//     value = vnode.children[0].text.replace(/^\s(.*)\s$/, '$1')
//   }
//   el.innerHTML = Format(binding.value, value).join('')
// }
// export const format = {
//   // inserted: bindFormatFunc,
//   update: bindFormatFunc
// }

// 千分位指令
export const thousands = {
  inserted: inputFormatter({
    // 格式化函数
    formatter(num, countrySetting) {
      num = num.toString()
      const num1 = num.split(`${countrySetting.thousands[1]}`)[0]
      const num2 = num.split(`${countrySetting.thousands[1]}`)[1]
      const c = num1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, `$1${countrySetting.thousands[0]}`)
      return num.toString().indexOf(`${countrySetting.thousands[1]}`) !== -1 ? c + `${countrySetting.thousands[1]}` + num2 : c
    },
    // 解析函数
    parser(val, countrySetting) {
      const step_1_reg = new RegExp(`\\${countrySetting.thousands[0]}`, 'g')
      val = val.toString().replace(step_1_reg, '')
      return val.indexOf(`${countrySetting.thousands[1]}`) === val.length - 1
        ? val
        : isNaN(Number(val))
          ? val
          : Number(val)
    },
    isNumber: true
  })
}

// 银行卡号
export const card = {
  inserted: inputFormatter({
    // 格式化函数
    formatter(num) {
      num = num.replace(/[^\d^\ ]+/g, '')
      if (/\S{5}/.test(num)) {
        return num
          .replace(/\s/g, '')
          .replace(/(.{4})/g, '$1 ')
          .replace(/(^\s*)|(\s*$)/g, '')
      }
      return num
    },
    // 解析函数
    parser(val) {
      val = val.toString()
        .replace(/\s+/g, '')
      return val
    }
  })
}

export const landscape = {
  bind(el, binding, vnode) {
    // const self = vnode.context

    function reset(init) {
      const width = document.documentElement.clientWidth
      const height = document.documentElement.clientHeight
      // 在竖屏状态我们通过添加transform:rotate(90deg)，来让这个页面横过来
      if (width < height) {
        el.style.webkitTransform = el.style.transform = `rotate(90deg)`
        el.style.width = `${height}px`
        el.style.height = `${width}px`
        el.style.webkitTransformOrigin = el.style.transformOrigin = `${width / 2}px center`
      } else {
        // el.style.webkitTransform = el.style.transform = `rotate(00deg)`
        // el.style.width = `${width}px`
        // el.style.height = `${height}px`
      }
    }
    reset(true)

    let timer = null
    el.fn = function (e) {
      clearTimeout(timer)
      timer = setTimeout(reset, 300)
    }
    el.fn()

    // if (window.landscapeResizeBind) return
    // console.log('绑定一次')
    // window.landscapeResizeBind = true // 整个生命周期绑定一次
    // window.addEventListener('resize', debounce(function () {
    //   console.log('resize', document.getElementsByClassName('section')[0])
    //   if (!document.getElementsByClassName('section')[0]) return
    //   const clientHeight = document.documentElement.clientHeight
    //   const clientWidth = document.documentElement.clientWidth
    //   const section = document.getElementsByClassName('section')[0]
    //   section.style.webkitTransform = section.style.transform = `rotate(90deg)`
    //   section.style.width = `${clientHeight}px`
    //   section.style.height = `${clientWidth}px`
    //   section.style.webkitTransformOrigin = section.style.transformOrigin = `${clientWidth / 2}px center`
    //   const right = document.getElementsByClassName('flex-right')[0]
    //   right.style.width = `${clientHeight - document.getElementById('live-photo-container').offsetHeight}px`
    // }, 1000), false)

    // window.addEventListener('resize', el.fn, false)

    // if ('onorientationchange' in window) {
    //   window.addEventListener('orientationchange', el.fn, false)
    // }
  },
  unbind(el, binding, vnode) {
    window.removeEventListener('resize', el.fn, false)
    window.removeEventListener('orientationchange', el.fn, false)
  }
}

